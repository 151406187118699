<template>
  <div>
    <!-- Headings -->
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Rejection Report</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0"></div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="grid grid-cols-12 gap-x-2">
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1"
              >Report Type</label
            >
            <TomSelect
              v-model="filter.report_type"
              :options="{
                placeholder: 'Report Type',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Party'
                  }
                }
              }"
              class="w-full"
            >
              <option value="1">Kapan Wise</option>
              <option value="2">Party Wise</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1"
              >Packet Type</label
            >
            <TomSelect
              v-model="filter.packet_type"
              :options="{
                placeholder: 'Packet Type',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Packet'
                  }
                }
              }"
              class="w-full"
            >
              <option value="0">All</option>
              <option value="rough">Rough</option>
              <option value="makeable">Makeable</option>
            </TomSelect>
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div class="mt-3">
            <label for="update-profile-form-6" class="form-label"
              >Packet / Lot</label
            >
            <input
              id="tabulator-html-filter-value"
              v-model="filter.kapan_id"
              type="text"
              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
              placeholder="Search Packet / Kapan"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div class="mt-2">
            <div class="flex items-center">
              <label for="update-profile-form-6" class="form-label"
                >Select Start Date</label
              ><input
                class="show-code form-check-switch mr-0 ml-3 h-6"
                type="checkbox"
                v-model="filter.issue_pending"
              />
            </div>
            <input
              type="date"
              id="update-profile-form-13"
              class="form-control"
              :disabled="filter.issue_pending"
              placeholder=""
              v-model="filter.issue_date"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1"
              >Select End Date</label
            >
            <input
              type="date"
              id="update-profile-form-13"
              class="form-control"
              placeholder=""
              :disabled="filter.issue_pending"
              v-model="filter.receive_date"
            />
          </div>
        </div>
        <div class="col-span-6 md:col-span-3 xl:col-span-2">
          <div>
            <label for="update-profile-form-6" class="form-label py-1"
              >Select Party</label
            >
            <TomSelect
              v-model="filter.party_id"
              :options="{
                placeholder: 'Select Party',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Party'
                  }
                }
              }"
              class="w-full"
              multiple
            >
              <option value="0">Select Party</option>
              <option
                v-for="party in page_data.parties"
                :key="party.id"
                :value="party.id"
                >{{ party.first_name }} {{ party.last_name }}</option
              >
            </TomSelect>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
          <div class="mt-7 flex pt-2">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full ml-2"
              tabindex="5"
              @click="getReportDetails()"
            >
              Generate
            </button>
          </div>
        </div>
        <div class="col-span-2 md:col-span-2 xl:col-span-1">
                <div class="mt-9 flex">

                    <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full ml-2" tabindex="5" @click="onExportXlsx">
                        Export
                    </button>
                </div>
              </div>
      </div>

      <div>
        <!-- BEgin: Process Report Start-->
        <div id="responsive-table" class="p-5" v-if="page_data.loading">
          <div class="preview">
            <div class="overflow-x-auto">
              <div
                class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
              >
                <LoadingIcon icon="bars" class="w-8 h-8" />
                <div class="text-center text-xs mt-2">Getting Latest Price</div>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="scrollbar-hidden">
            <div
              class="intro-y col-span-12 overflow-auto tableFixHead mt-3 border-2"
            >
              <table class="w-full border-0" v-if="api_filter.report_type == 1">
                <thead>
                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                    <th class="py-2 text-xs whitespace-nowrap border">#</th>
                    <th class="py-2 text-xs whitespace-nowrap border">
                      KAPAN NO
                    </th>
                    <th class="py-2 text-xs whitespace-nowrap border">
                      Type
                    </th>
                    <th class="py-2 text-xs whitespace-nowrap border">PARTY</th>
                    <th class="py-2 text-xs whitespace-nowrap border">
                      CREATED AT
                    </th>
                    <th
                      class="py-2 text-xs whitespace-nowrap border"
                      colspan="2"
                    >
                      PURCHASE STOCK
                    </th>
                    <th
                      class="py-2 text-xs whitespace-nowrap border"
                      colspan="2"
                    >
                      CURRENT STOCK
                    </th>
                    <th
                      class="py-2 text-xs whitespace-nowrap border"
                      colspan="2"
                    >
                      PROCESS STOCK
                    </th>
                    <th
                      class="py-2 text-xs whitespace-nowrap border"
                      colspan="2"
                    >
                      DELIVERED STOCK
                    </th>
                    <th
                      class="py-2 text-xs whitespace-nowrap border"
                      colspan="2"
                    >
                      FAILED STOCK
                    </th>
                  </tr>
                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                    <th class="py-2 text-xs whitespace-nowrap border">#</th>
                    <th class="py-2 text-xs whitespace-nowrap border">
                      KAPAN NO
                    </th>
                    <th class="py-2 text-xs whitespace-nowrap border">
                      Type
                    </th>
                    <th class="py-2 text-xs whitespace-nowrap border">PARTY</th>
                    <th class="py-2 text-xs whitespace-nowrap border">
                      CREATED AT
                    </th>
                    <th class="py-2 text-xs whitespace-nowrap border">
                      TOTAL PACKETS
                    </th>
                    <th class="py-2 text-xs whitespace-nowrap border">
                      TOTAL WEIGHT
                    </th>
                    <th class="py-2 text-xs whitespace-nowrap border">
                      TOTAL PACKETS
                    </th>
                    <th class="py-2 text-xs whitespace-nowrap border">
                      TOTAL WEIGHHT
                    </th>
                    <!-- <th class="py-2 text-xs whitespace-nowrap border">CURRENT WEIGHT</th> -->
                    <th class="py-2 text-xs whitespace-nowrap border">
                      TOTAL PACKETS
                    </th>
                    <!-- <th class="py-2 text-xs whitespace-nowrap border">TOTAL WEIGHHT</th> -->
                    <th class="py-2 text-xs whitespace-nowrap border">
                      CURRENT WEIGHT
                    </th>
                    <th class="py-2 text-xs whitespace-nowrap border">
                      TOTAL PACKETS
                    </th>
                    <th class="py-2 text-xs whitespace-nowrap border">
                      CURRENT WEIGHT
                    </th>
                    <th class="py-2 text-xs whitespace-nowrap border">
                      TOTAL PACKETS
                    </th>
                    <!-- <th class="py-2 text-xs whitespace-nowrap border">TOTAL WEIGHHT</th> -->
                    <th class="py-2 text-xs whitespace-nowrap border">
                      DELIVERED WEIGHT
                    </th>
                  </tr>
                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                    <td class="py-2 border-b text-xs text-center border-r"></td>
                    <td class="py-2 border-b text-xs text-center border-r">
                      {{ page_data.total.total_records }}
                    </td>
                    <td class="py-2 border-b text-xs text-center border-r"></td>
                    <td class="py-2 border-b text-xs text-center border-r"></td>
                    <td class="py-2 border-b text-xs text-center border-r"></td>
                    <td class="py-2 border-b text-xs text-center border-r">
                      {{
                        page_data.total.total_packets
                          ? page_data.total.total_packets
                          : 0
                      }}
                    </td>
                    <td class="py-2 border-b text-xs text-center border-r">
                      {{
                        page_data.total.total_weight
                          ? $h.myFloat(page_data.total.total_weight)
                          : 0
                      }}
                      Ct
                    </td>
                    <td class="py-2 border-b text-xs text-center border-r">
                      {{
                        page_data.total.available_total_packets
                          ? $h.myFloat(page_data.total.available_total_packets)
                          : 0
                      }}
                    </td>
                    <!-- <td class="py-2 border-b text-xs text-center border-r">{{ (page_data.total.available_total_weight ? page_data.total.available_total_weight : 0) }} Ct</td> -->
                    <td class="py-2 border-b text-xs text-center border-r">
                      {{
                        page_data.total.available_cr_weight
                          ? $h.myFloat(page_data.total.available_cr_weight)
                          : 0
                      }}
                      Ct
                    </td>
                    <td class="py-2 border-b text-xs text-center border-r">
                      {{
                        page_data.total.process_total_packets
                          ? $h.myFloat(page_data.total.process_total_packets)
                          : 0
                      }}
                    </td>
                    <!-- <td class="py-2 border-b text-xs text-center border-r">{{ (page_data.total.available_total_weight ? page_data.total.available_total_weight : 0) }} Ct</td> -->
                    <td class="py-2 border-b text-xs text-center border-r">
                      {{
                        page_data.total.process_cr_weight
                          ? $h.myFloat(page_data.total.process_cr_weight)
                          : 0
                      }}
                      Ct
                    </td>
                    <td class="py-2 border-b text-xs text-center border-r">
                      {{
                        page_data.total.delivered_total_packets
                          ? page_data.total.delivered_total_packets
                          : 0
                      }}
                    </td>
                    <!-- <td class="py-2 border-b text-xs text-center border-r">{{ (page_data.total.delivered_total_weight ? $h.myFloat(page_data.total.delivered_total_weight) : 0) }} Ct</td> -->
                    <td class="py-2 border-b text-xs text-center border-r">
                      {{
                        page_data.total.delivered_cr_weight
                          ? $h.myFloat(page_data.total.delivered_cr_weight)
                          : 0
                      }}
                      Ct
                    </td>
                    <td class="py-2 border-b text-xs text-center border-r">
                      {{
                        page_data.total.failed_total_packets
                          ? page_data.total.failed_total_packets
                          : 0
                      }}
                    </td>
                    <!-- <td class="py-2 border-b text-xs text-center border-r">{{ (page_data.total.failed_total_weight ? $h.myFloat(page_data.total.failed_total_weight) : 0) }} Ct</td> -->
                    <td class="py-2 border-b text-xs text-center border-r">
                      {{
                        page_data.total.failed_cr_weight
                          ? page_data.total.failed_cr_weight
                          : 0
                      }}
                      Ct
                    </td>
                  </tr>
                </thead>
                <tbody v-for="(item, index) in page_data.items" :key="index">
                  <tr
                    class="border-b hover:bg-gray-200 p-0"
                    :class="{ 'bg-gray-100': index % 2 == 0 }"
                  >
                    <td
                      class="py-2 border-b text-xs text-center border-r whitespace-nowrap"
                    >
                      {{ index + 1 }}
                    </td>
                    <td
                      class="py-2 border-b text-xs text-center border-r whitespace-nowrap"
                    >
                      {{ item.lot_no }} ({{ item.voucher_no }})
                    </td>
                    <td
                      class="py-2 border-b text-xs text-center border-r whitespace-nowrap"
                    >
                      {{ item.type }}
                    </td>
                    <td
                      class="py-2 border-b text-xs text-center border-r whitespace-nowrap"
                    >
                      {{ item.firm_name }}
                    </td>
                    <td
                      class="py-2 border-b text-xs text-center border-r whitespace-nowrap"
                    >
                      {{ item.inward_date }}
                    </td>
                    <td
                      class="py-2 border-b text-xs text-center border-r whitespace-nowrap"
                    >
                      {{ item.total_packets ? item.total_packets : 0 }}
                    </td>
                    <td
                      class="py-2 border-b text-xs text-center border-r whitespace-nowrap"
                    >
                      {{
                        item.total_weight ? $h.myFloat(item.total_weight) : 0
                      }}
                      Ct
                    </td>
                    <td
                      class="py-2 border-b text-xs text-center border-r whitespace-nowrap"
                    >
                      {{
                        item.available_total_packets
                          ? $h.myFloat(item.available_total_packets)
                          : 0
                      }}
                    </td>
                    <!-- <td class="py-2 border-b text-xs text-center border-r whitespace-nowrap">{{ (item.available_total_weight ? item.available_total_weight : 0) }} Ct</td> -->
                    <td
                      class="py-2 border-b text-xs text-center border-r whitespace-nowrap"
                    >
                      {{
                        item.available_cr_weight
                          ? $h.myFloat(item.available_cr_weight)
                          : 0
                      }}
                      Ct
                    </td>
                    <td
                      class="py-2 border-b text-xs text-center border-r whitespace-nowrap"
                    >
                      {{
                        item.process_total_packets
                          ? $h.myFloat(item.process_total_packets)
                          : 0
                      }}
                    </td>
                    <!-- <td class="py-2 border-b text-xs text-center border-r whitespace-nowrap">{{ (item.available_total_weight ? item.available_total_weight : 0) }} Ct</td> -->
                    <td
                      class="py-2 border-b text-xs text-center border-r whitespace-nowrap"
                    >
                      {{
                        item.process_cr_weight
                          ? $h.myFloat(item.process_cr_weight)
                          : 0
                      }}
                      Ct
                    </td>
                    <td
                      class="py-2 border-b text-xs text-center border-r whitespace-nowrap"
                    >
                      {{
                        item.delivered_total_packets
                          ? item.delivered_total_packets
                          : 0
                      }}
                    </td>
                    <!-- <td class="py-2 border-b text-xs text-center border-r whitespace-nowrap">{{ (item.delivered_total_weight ? $h.myFloat(item.delivered_total_weight) : 0) }} Ct</td> -->
                    <td
                      class="py-2 border-b text-xs text-center border-r whitespace-nowrap"
                    >
                      {{
                        item.delivered_cr_weight
                          ? $h.myFloat(item.delivered_cr_weight)
                          : 0
                      }}
                      Ct
                    </td>
                    <td
                      class="py-2 border-b text-xs text-center border-r whitespace-nowrap"
                    >
                      {{
                        item.failed_total_packets
                          ? item.failed_total_packets
                          : 0
                      }}
                    </td>
                    <!-- <td class="py-2 border-b text-xs text-center border-r whitespace-nowrap">{{ (item.failed_total_weight ? $h.myFloat(item.failed_total_weight) : 0) }} Ct</td> -->
                    <td
                      class="py-2 border-b text-xs text-center border-r whitespace-nowrap"
                    >
                      {{ item.failed_cr_weight ? item.failed_cr_weight : 0 }} Ct
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="w-full border-0" v-if="api_filter.report_type == 2">
                <thead>
                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                    <th class="py-2 text-xs whitespace-nowrap border">#</th>
                    <th class="py-2 text-xs whitespace-nowrap border">PARTY</th>
                    <th
                      class="py-2 text-xs whitespace-nowrap border"
                      colspan="2"
                    >
                      PURCHASE STOCK
                    </th>
                    <th
                      class="py-2 text-xs whitespace-nowrap border"
                      colspan="2"
                    >
                      CURRENT STOCK
                    </th>
                    <th
                      class="py-2 text-xs whitespace-nowrap border"
                      colspan="2"
                    >
                      PROCESS STOCK
                    </th>
                    <th
                      class="py-2 text-xs whitespace-nowrap border"
                      colspan="2"
                    >
                      DELIVERED STOCK
                    </th>
                    <th
                      class="py-2 text-xs whitespace-nowrap border"
                      colspan="2"
                    >
                      FAILED STOCK
                    </th>
                  </tr>
                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                    <th class="py-2 text-xs whitespace-nowrap border">#</th>
                    <th class="py-2 text-xs whitespace-nowrap border">PARTY</th>
                    <th class="py-2 text-xs whitespace-nowrap border">
                      TOTAL PACKETS
                    </th>
                    <th class="py-2 text-xs whitespace-nowrap border">
                      TOTAL WEIGHT
                    </th>
                    <th class="py-2 text-xs whitespace-nowrap border">
                      TOTAL PACKETS
                    </th>
                    <th class="py-2 text-xs whitespace-nowrap border">
                      TOTAL WEIGHHT
                    </th>
                    <!-- <th class="py-2 text-xs whitespace-nowrap border">CURRENT WEIGHT</th> -->
                    <th class="py-2 text-xs whitespace-nowrap border">
                      TOTAL PACKETS
                    </th>
                    <!-- <th class="py-2 text-xs whitespace-nowrap border">TOTAL WEIGHHT</th> -->
                    <th class="py-2 text-xs whitespace-nowrap border">
                      CURRENT WEIGHT
                    </th>
                    <th class="py-2 text-xs whitespace-nowrap border">
                      TOTAL PACKETS
                    </th>
                    <!-- <th class="py-2 text-xs whitespace-nowrap border">TOTAL WEIGHHT</th> -->
                    <th class="py-2 text-xs whitespace-nowrap border">
                      CURRENT WEIGHT
                    </th>
                    <th class="py-2 text-xs whitespace-nowrap border">
                      TOTAL PACKETS
                    </th>
                    <!-- <th class="py-2 text-xs whitespace-nowrap border">TOTAL WEIGHHT</th> -->
                    <th class="py-2 text-xs whitespace-nowrap border">
                      DELIVERED WEIGHT
                    </th>
                  </tr>
                  <tr class="border-b bg-gray-200 dark:bg-dark-1">
                    <td class="py-2 border-b text-xs text-center border-r"></td>
                    <td class="py-2 border-b text-xs text-center border-r">
                      {{ page_data.total.total_records }}
                    </td>
                    <td class="py-2 border-b text-xs text-center border-r">
                      {{
                        page_data.total.total_packets
                          ? page_data.total.total_packets
                          : 0
                      }}
                    </td>
                    <td class="py-2 border-b text-xs text-center border-r">
                      {{
                        page_data.total.total_weight
                          ? $h.myFloat(page_data.total.total_weight)
                          : 0
                      }}
                      Ct
                    </td>
                    <td class="py-2 border-b text-xs text-center border-r">
                      {{
                        page_data.total.available_total_packets
                          ? $h.myFloat(page_data.total.available_total_packets)
                          : 0
                      }}
                    </td>
                    <!-- <td class="py-2 border-b text-xs text-center border-r">{{ (page_data.total.available_total_weight ? page_data.total.available_total_weight : 0) }} Ct</td> -->
                    <td class="py-2 border-b text-xs text-center border-r">
                      {{
                        page_data.total.available_cr_weight
                          ? $h.myFloat(page_data.total.available_cr_weight)
                          : 0
                      }}
                      Ct
                    </td>
                    <td class="py-2 border-b text-xs text-center border-r">
                      {{
                        page_data.total.process_total_packets
                          ? $h.myFloat(page_data.total.process_total_packets)
                          : 0
                      }}
                    </td>
                    <!-- <td class="py-2 border-b text-xs text-center border-r">{{ (page_data.total.available_total_weight ? page_data.total.available_total_weight : 0) }} Ct</td> -->
                    <td class="py-2 border-b text-xs text-center border-r">
                      {{
                        page_data.total.process_cr_weight
                          ? $h.myFloat(page_data.total.process_cr_weight)
                          : 0
                      }}
                      Ct
                    </td>
                    <td class="py-2 border-b text-xs text-center border-r">
                      {{
                        page_data.total.delivered_total_packets
                          ? page_data.total.delivered_total_packets
                          : 0
                      }}
                    </td>
                    <!-- <td class="py-2 border-b text-xs text-center border-r">{{ (page_data.total.delivered_total_weight ? $h.myFloat(page_data.total.delivered_total_weight) : 0) }} Ct</td> -->
                    <td class="py-2 border-b text-xs text-center border-r">
                      {{
                        page_data.total.delivered_cr_weight
                          ? $h.myFloat(page_data.total.delivered_cr_weight)
                          : 0
                      }}
                      Ct
                    </td>
                    <td class="py-2 border-b text-xs text-center border-r">
                      {{
                        page_data.total.failed_total_packets
                          ? page_data.total.failed_total_packets
                          : 0
                      }}
                    </td>
                    <!-- <td class="py-2 border-b text-xs text-center border-r">{{ (page_data.total.failed_total_weight ? $h.myFloat(page_data.total.failed_total_weight) : 0) }} Ct</td> -->
                    <td class="py-2 border-b text-xs text-center border-r">
                      {{
                        page_data.total.failed_cr_weight
                          ? page_data.total.failed_cr_weight
                          : 0
                      }}
                      Ct
                    </td>
                  </tr>
                </thead>
                <tbody v-for="(item, index) in page_data.items" :key="index">
                  <tr
                    class="border-b hover:bg-gray-200 p-0"
                    :class="{ 'bg-gray-100': index % 2 == 0 }"
                  >
                    <td
                      class="py-2 border-b text-xs text-center border-r whitespace-nowrap"
                    >
                      {{ index + 1 }}
                    </td>
                    <td
                      class="py-2 border-b text-xs text-center border-r whitespace-nowrap"
                    >
                      {{ item.firm_name }}
                    </td>
                    <td
                      class="py-2 border-b text-xs text-center border-r whitespace-nowrap"
                    >
                      {{ item.total_packets ? item.total_packets : 0 }}
                    </td>
                    <td
                      class="py-2 border-b text-xs text-center border-r whitespace-nowrap"
                    >
                      {{
                        item.total_weight ? $h.myFloat(item.total_weight) : 0
                      }}
                      Ct
                    </td>
                    <td
                      class="py-2 border-b text-xs text-center border-r whitespace-nowrap"
                    >
                      {{
                        item.available_total_packets
                          ? $h.myFloat(item.available_total_packets)
                          : 0
                      }}
                    </td>
                    <!-- <td class="py-2 border-b text-xs text-center border-r whitespace-nowrap">{{ (item.available_total_weight ? item.available_total_weight : 0) }} Ct</td> -->
                    <td
                      class="py-2 border-b text-xs text-center border-r whitespace-nowrap"
                    >
                      {{
                        item.available_cr_weight
                          ? $h.myFloat(item.available_cr_weight)
                          : 0
                      }}
                      Ct
                    </td>
                    <td
                      class="py-2 border-b text-xs text-center border-r whitespace-nowrap"
                    >
                      {{
                        item.process_total_packets
                          ? $h.myFloat(item.process_total_packets)
                          : 0
                      }}
                    </td>
                    <!-- <td class="py-2 border-b text-xs text-center border-r whitespace-nowrap">{{ (item.available_total_weight ? item.available_total_weight : 0) }} Ct</td> -->
                    <td
                      class="py-2 border-b text-xs text-center border-r whitespace-nowrap"
                    >
                      {{
                        item.process_cr_weight
                          ? $h.myFloat(item.process_cr_weight)
                          : 0
                      }}
                      Ct
                    </td>
                    <td
                      class="py-2 border-b text-xs text-center border-r whitespace-nowrap"
                    >
                      {{
                        item.delivered_total_packets
                          ? item.delivered_total_packets
                          : 0
                      }}
                    </td>
                    <!-- <td class="py-2 border-b text-xs text-center border-r whitespace-nowrap">{{ (item.delivered_total_weight ? $h.myFloat(item.delivered_total_weight) : 0) }} Ct</td> -->
                    <td
                      class="py-2 border-b text-xs text-center border-r whitespace-nowrap"
                    >
                      {{
                        item.delivered_cr_weight
                          ? $h.myFloat(item.delivered_cr_weight)
                          : 0
                      }}
                      Ct
                    </td>
                    <td
                      class="py-2 border-b text-xs text-center border-r whitespace-nowrap"
                    >
                      {{
                        item.failed_total_packets
                          ? item.failed_total_packets
                          : 0
                      }}
                    </td>
                    <!-- <td class="py-2 border-b text-xs text-center border-r whitespace-nowrap">{{ (item.failed_total_weight ? $h.myFloat(item.failed_total_weight) : 0) }} Ct</td> -->
                    <td
                      class="py-2 border-b text-xs text-center border-r whitespace-nowrap"
                    >
                      {{ item.failed_cr_weight ? item.failed_cr_weight : 0 }} Ct
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import axios from 'axios'
import moment from 'moment'
import {
  defineComponent,
  onMounted,
  reactive,
  toRef,
  watch,
  computed
} from 'vue'
import store from '@/store'
import { helper as $h } from '@/utils/helper'
import FileDownload from "js-file-download";

export default defineComponent({
  setup() {
    const user_details = computed(() => store.state.user.currentUser)
    const page_data = reactive({
      total: [],
      items: [],
      parties: [],
      manager_items: [],
      process_items: [],
      lot_items: [],
      employee_items: [],
      loading: false
    })

    const filter = reactive({
      kapan_id: '',
      packet_type: '0',
      report_type: '1',
      party_id: [],
      issue_pending: true,
      issue_date: moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
      receive_date: moment().format('YYYY-MM-DD')
    })

    const api_filter = reactive({
      kapan_id: '',
      packet_type: '0',
      report_type: '1',
      party_id: [],
      issue_pending: true,
      issue_date: moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
      receive_date: moment().format('YYYY-MM-DD')
    })

    const getReportDetails = async () => {
      api_filter.party_id = filter.party_id
      api_filter.kapan_id = filter.kapan_id
      api_filter.report_type = filter.report_type
      api_filter.issue_pending = filter.issue_pending
      api_filter.issue_date = filter.issue_date
      api_filter.receive_date = filter.receive_date
      api_filter.packet_type = filter.packet_type

      const params = {
        report_type: api_filter.report_type,
        party_id: api_filter.party_id,
        packet_type: (api_filter.packet_type == "0" ? ""  : api_filter.packet_type),
        kapan_id: api_filter.kapan_id,
        issue_pending: api_filter.issue_pending,
        issue_date: api_filter.issue_date,
        receive_date: api_filter.receive_date
      }
      page_data.loading = true

      let promise = axios({
        url: 'api/report/kapan_stocks',
        method: 'post',
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.items = data.items
          page_data.total = data.total
          page_data.loading = false
        })
        .catch(_error => {
          page_data.items = []
          page_data.total = []
          page_data.loading = false
        })
    }

    const getLotDetails = async () => {
      let params = {}
      let api_url = 'api/master/dp_lot_all_details'

      let promise = axios({
        url: api_url,
        method: 'post',
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            Accept: 'application/json'
          }
        }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          filter.kapan_id = ''
          page_data.lot_items = data.items
        })
        .catch(_error => {
          filter.kapan_id = ''
          page_data.lot_items = []
        })
    }

    const getPartyDetails = () => {
      let params = {
        // department_id : window.btoa(filter.department_id)
      }
      let api_url = 'api/master/dp_parties'
      let promise = axios({
        url: api_url,
        method: 'post',
        data: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: { headers: { Accept: 'application/json' } }
      })
      return promise
        .then(result => result.data)
        .then(data => {
          page_data.parties = data.items
        })
        .catch(_error => {
          page_data.parties = []
        })
    }

    function onResetFilter() {
      filter.report_type = '1'
      filter.kapan_id = ''
      filter.packet_type = '0'
      filter.issue_pending = true
      filter.issue_date = moment()
        .startOf('month')
        .format('YYYY-MM-DD')
      filter.receive_date = moment().format('YYYY-MM-DD')

      getReportDetails()
    }
    onMounted(() => {
      getReportDetails()
      getPartyDetails()
    })

    const onExportXlsx = () =>{

      let filters = {
        report_type: api_filter.report_type,
        party_id: api_filter.party_id,
        packet_type: (api_filter.packet_type == "0" ? ""  : api_filter.packet_type),
        kapan_id: api_filter.kapan_id,
        issue_pending: api_filter.issue_pending,
        issue_date: api_filter.issue_date,
        receive_date: api_filter.receive_date
      }
      const today = new Date();
      const date = today.getDate() + '' + (today.getMonth() + 1) + '' + today.getFullYear();
      // const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date;
      let filename = "KapanSummmary_" +dateTime + ".xlsx";

      let promise = axios({
          url: "api/report/kapan_stocks_export",
          method: "post",
          data: filters,
          baseURL: BASE_URL,
          headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
          },
          config: {
              headers: {
                  Accept: "application/json"
              }
          },
          responseType: "blob",
      });

      return promise
          .then((result) => result.data)
          .then((data) => {
              FileDownload(data, filename);
          })
          .catch((_error) => {
              return [];
          });
      }
    return {
      page_data,
      filter,
      api_filter,
      user_details,
      getReportDetails,
      onResetFilter,
      onExportXlsx
    }
  }
})
</script>
<style scoped>
.tableFixHead {
  overflow-y: auto; /* make the table scrollable if height is more than 200 px  */
  max-height: 650px; /* gives an initial height of 200px to the table */
}
.tableFixHead thead {
  position: sticky; /* make the table heads sticky */
  top: 0px; /* table head will be placed from the top of the table and sticks to it */
}
</style>
