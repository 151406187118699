<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Outward Transaction Detail</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button @click="print_jangad" class="btn btn-primary btn-sm shadow-md mr-2">
          <PrinterIcon class="w-4 h-4" />
        </button>
      </div>
    </div>
    <div class="grid grid-cols-12 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">

        <!-- BEGIN: Input -->
        <div class="intro-y box" v-if="transaction_details != null">
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-4 xl:col-span-4">

                <div>
                  <label for="update-profile-form-6" class="form-label font-bold">Transaction No. :&nbsp;</label><label
                    for="update-profile-form-6" class="form-label"> {{ transaction_details.transaction_code }}</label>
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold">Polish Pieces : &nbsp;</label><label
                    for="update-profile-form-6" class="form-label"> {{ transaction_details.total_pieces }}</label>
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold">polish Weight : &nbsp;</label><label
                    for="update-profile-form-6" class="form-label"> {{ $h.myFloat(transaction_details.total_weight) }}
                    (Ct)</label>
                </div>

              </div>
              <div class="col-span-4 xl:col-span-4">

                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold">{{transaction_details.transaction_type == 1 ? 'Outward' : 'Return'}} Date : &nbsp;</label><label
                    for="update-profile-form-6" class="form-label"> {{ transaction_details.issue_date }}</label>
                </div>
                <div class="mt-3" v-if="transaction_details.creator">
                  <label for="update-profile-form-6" class="form-label font-bold">Created By : &nbsp;</label><label
                    for="update-profile-form-6" class="form-label"> {{ (transaction_details.creator.first_name + ' ' +
                      transaction_details.creator.last_name) }} (Ct)</label>
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold">{{transaction_details.transaction_type == 1 ? 'Outward' : 'Return'}} Date Time : &nbsp;</label><label
                    for="update-profile-form-6" class="form-label"> {{ transaction_details.created_time }}</label>
                </div>
                <div class="mt-3">
                  <label for="update-profile-form-6" class="form-label font-bold">Status : &nbsp;</label>
                  <label for="update-profile-form-6" class="form-label">
                    <span v-if="transaction_details.status == 1" class="text-theme-11 font-bold">Delivered</span>
                    <span v-if="transaction_details.status == 2" class="text-theme-9 font-bold">Returned</span>
                    <span v-if="transaction_details.status == 3" class="text-theme-6 font-bold">Cancelled</span>
                  </label>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="intro-y box mt-10" v-if="transaction_details != null">
          <div class="col-span-12 xl:col-span-12">
            <div class="flex flex-col sm:flex-row items-center pt-5 pb-5 border-b border-gray-200 dark:border-dark-5">
              <h2 class="font-medium text-base mr-auto pl-5">Selected Packets</h2>
            </div>
            <div class="p-5">
              <div class="grid grid-cols-12 gap-x-5">
                <div class="col-span-12 overflow-scroll">
                  <table class="border w-full" v-if="transaction_details.transaction_type == 1">
                    <tr class="sticky top-0 pl-5 pr-5 pb-2 mt-3" style="background-color:#fff;">

                      <td class="font-bold text-left">
                        <p>Sr. No</p>
                      </td>
                      <td class="font-bold text-center whitespace-nowrap border">
                        <p>Type</p>
                      </td>
                      <td class="font-bold text-center whitespace-nowrap border">
                        <p>Packet No</p>
                      </td>
                      <td class="font-bold text-center whitespace-nowrap border">
                        <p>Stock No</p>
                      </td>
                      <td class="font-bold text-center whitespace-nowrap border">
                        <p>Rough WT</p>
                      </td>
                      <td class="font-bold text-center whitespace-nowrap border">
                        <p>Polish WT</p>
                      </td>
                      <td class="font-bold text-center whitespace-nowrap border">
                        <p>Final Polish</p>
                      </td>
                      <td class="font-bold text-center whitespace-nowrap border">
                        <p>Shape</p>
                      </td>
                      <td class="font-bold text-center whitespace-nowrap border">
                        <p>Color</p>
                      </td>
                      <td class="font-bold text-center whitespace-nowrap border">
                        <p>Purity</p>
                      </td>
                      <td class="font-bold text-center whitespace-nowrap border">
                        <p>Cut</p>
                      </td>
                      <td class="font-bold text-center whitespace-nowrap border">
                        <p>Polish</p>
                      </td>
                      <td class="font-bold text-center whitespace-nowrap border">
                        <p>Symmetry</p>
                      </td>
                      <td class="font-bold text-center whitespace-nowrap border">
                        <p>Job Bhav</p>
                      </td>
                      <td class="font-bold text-center whitespace-nowrap border">
                        <p>Job Majuri</p>
                      </td>
                      <td class="font-bold text-center whitespace-nowrap border">
                        <p>Date</p>
                      </td>
                      <td class="font-bold text-center whitespace-nowrap border">
                        <p>Status</p>
                      </td>
                    </tr>

                    <tr class="border" v-for="(field, index) in transaction_packets" :key="index">
                      <td class="p-1 text-left border whitespace-nowrap">
                        <label class="p-2 mt-2">{{ index + 1 }}</label>
                      </td>
                      <td class="p-2 text-center border whitespace-nowrap">
                        <span v-if="field.job_type == 1" class="text-theme-1 font-bold">Jon Done</span>
                        <span v-if="field.job_type == 2" class="text-theme-1 font-bold">Repairing</span>
                        <span v-if="field.job_type == 3" class="text-theme-1 font-bold">Released</span>
                          <span v-if="field.job_type == null" class="text-theme-6 font-bold">Cancelled</span>
                      </td>
                      <td class="p-2 text-center border whitespace-nowrap">
                        <label class="p-2 mt-3">{{ field.packet_no }}</label>
                      </td>
                      <td class="p-2 text-center border whitespace-nowrap">
                        <label class="p-2 mt-3">{{ field.stock_no }}</label>
                      </td>
                      <td class="p-2 text-center border whitespace-nowrap">
                        <label class="p-2 mt-3">{{ field.rough_weight }}</label>
                      </td>
                      <td class="p-2 text-center border whitespace-nowrap">
                        <label class="p-2 mt-3">{{ field.polish_weight }}</label>
                      </td>
                      <td class="p-2 text-center border whitespace-nowrap">
                        <label class="p-2 mt-3">{{ field.final_weight }}</label>
                      </td>
                      <td class="p-2 text-center border whitespace-nowrap">
                        <label class="p-2 mt-3">{{ field.shape }}</label>
                      </td>
                      <td class="p-2 text-center border whitespace-nowrap">
                        <label class="p-2 mt-3">{{ field.color }}</label>
                      </td>
                      <td class="p-2 text-center border whitespace-nowrap">
                        <label class="p-2 mt-3">{{ field.purity }}</label>
                      </td>
                      <td class="p-2 text-center border whitespace-nowrap">
                        <label class="p-2 mt-3">{{ field.cut }}</label>
                      </td>
                      <td class="p-2 text-center border whitespace-nowrap">
                        <label class="p-2 mt-3">{{ field.polish }}</label>
                      </td>
                      <td class="p-2 text-center border whitespace-nowrap">
                        <label class="p-2 mt-3">{{ field.symmetry }}</label>
                      </td>
                      <td class="p-2 text-center border whitespace-nowrap">
                        <label class="p-2 mt-3">{{ field.karigar_bhav }}</label>
                      </td>
                      <td class="p-2 text-center border whitespace-nowrap">
                        <label class="p-2 mt-3">{{ field.job_done_value }}</label>
                      </td>
                      <td class="p-2 text-center border whitespace-nowrap">
                        <label class="p-2 mt-3">{{ field.outward_date }}</label>
                      </td>
                      <td class="p-2 text-left border whitespace-nowrap">
                        <label class="p-2 mt-3">
                          <span v-if="field.status == 1" class="text-theme-11 font-bold">Delivered</span>
                          <span v-if="field.status == 2" class="text-theme-9 font-bold">Returned</span>
                          <span v-if="field.status == 3" class="text-theme-6 font-bold">Cancelled</span>
                        </label>
                      </td>
                    </tr>
                  </table>
                  <table class="border w-full" v-if="transaction_details.transaction_type == 2">
                    <tr class="sticky top-0 pl-5 pr-5 pb-2 mt-3" style="background-color:#fff;">

                      <td class="font-bold text-left">
                        <p>Sr. No</p>
                      </td>
                      <td class="font-bold text-center whitespace-nowrap border">
                        <p>Packet No</p>
                      </td>
                      <td class="font-bold text-center whitespace-nowrap border">
                        <p>Stock No</p>
                      </td>
                      <td class="font-bold text-center whitespace-nowrap border">
                        <p>Rough WT</p>
                      </td>
                      <td class="font-bold text-center whitespace-nowrap border">
                        <p>Polish WT</p>
                      </td>
                      <td class="font-bold text-center whitespace-nowrap border">
                        <p>Shape</p>
                      </td>
                      <td class="font-bold text-center whitespace-nowrap border">
                        <p>Color</p>
                      </td>
                      <td class="font-bold text-center whitespace-nowrap border">
                        <p>Purity</p>
                      </td>
                      <td class="font-bold text-center whitespace-nowrap border">
                        <p>Cut</p>
                      </td>
                      <td class="font-bold text-center whitespace-nowrap border">
                        <p>Polish</p>
                      </td>
                      <td class="font-bold text-center whitespace-nowrap border">
                        <p>Symmetry</p>
                      </td>
                      <td class="font-bold text-center whitespace-nowrap border">
                        <p>Status</p>
                      </td>
                    </tr>

                    <tr class="border" v-for="(field, index) in transaction_packets" :key="index">
                      <td class="p-1 text-left border whitespace-nowrap">
                        <label class="p-2 mt-2">{{ index + 1 }}</label>
                      </td>
                      <td class="p-2 text-center border whitespace-nowrap">
                        <label class="p-2 mt-3">{{ field.packet_no }}</label>
                      </td>
                      <td class="p-2 text-center border whitespace-nowrap">
                        <label class="p-2 mt-3">{{ field.stock_no }}</label>
                      </td>
                      <td class="p-2 text-center border whitespace-nowrap">
                        <label class="p-2 mt-3">{{ field.rough_weight }}</label>
                      </td>
                      <td class="p-2 text-center border whitespace-nowrap">
                        <label class="p-2 mt-3">{{ field.polish_weight }}</label>
                      </td>
                      <td class="p-2 text-center border whitespace-nowrap">
                        <label class="p-2 mt-3">{{ field.shape }}</label>
                      </td>
                      <td class="p-2 text-center border whitespace-nowrap">
                        <label class="p-2 mt-3">{{ field.color }}</label>
                      </td>
                      <td class="p-2 text-center border whitespace-nowrap">
                        <label class="p-2 mt-3">{{ field.purity }}</label>
                      </td>
                      <td class="p-2 text-center border whitespace-nowrap">
                        <label class="p-2 mt-3">{{ field.cut }}</label>
                      </td>
                      <td class="p-2 text-center border whitespace-nowrap">
                        <label class="p-2 mt-3">{{ field.polish }}</label>
                      </td>
                      <td class="p-2 text-center border whitespace-nowrap">
                        <label class="p-2 mt-3">{{ field.symmetry }}</label>
                      </td>
                      <td class="p-2 text-left border whitespace-nowrap">
                        <label class="p-2 mt-3">
                          <span v-if="field.status == 1" class="text-theme-11 font-bold">Returned</span>
                          <span v-if="field.status == 3" class="text-theme-6 font-bold">Cancelled</span>
                        </label>
                      </td>
                    </tr>
                  </table>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  reactive,
  ref
} from 'vue'
import {
  mapGetters,
  mapActions
} from 'vuex'

import {
  BASE_URL
} from '@/constants/config.js'
import axios from 'axios'
import datetime from 'vuejs-datetimepicker';
import {
  helper as $h
} from '@/utils/helper'
import moment from 'moment';
import FileDownload from "js-file-download";
import store from '@/store';
import {
  useRoute
} from 'vue-router';
// const router = useRouter();
// const route = useRoute();

export default defineComponent({
  components: {
    datetime
  },
  setup() {
    const user_details = computed(() => store.state.user.currentUser)
    const route = useRoute()
    const transaction_details = ref(null)
    const transaction_packets = ref()
    const swal = inject('$swal');


    const get_records = (id) => {
      const params = {
        trans_id: id
      };
      let promise = axios({
        url: "api/job-done/item_details",
        method: "POST",
        params: params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {

          if (data.status == 'error') {

            transaction_details.value = [];
            transaction_packets.value = [];

            swal("Error!", data.message, "error");
          } else {

            transaction_details.value = data.transaction;
            transaction_packets.value = data.items;
          }
        })
        .catch(_error => {

          transaction_details.value = [];
          transaction_packets.value = [];

          swal("Error!", data.message, "error");
        });
    }
    const print_jangad = () => {
      let url = BASE_URL + 'outward_prints/' + '?trans_id=' + route.params.id
      window.open(url, '_blank');
    }

    onMounted(() => {
      get_records(route.params.id)
    })

    return {
      transaction_details,
      transaction_packets,
      user_details,
      print_jangad,
    }
  }

})
</script>
