<template>
  <!-- BEGIN: Profile Info -->
  <div id="basic-non-sticky-notification-packet" class="toastify-content hidden flex flex-col sm:flex-row">
    <div class="font-medium text-primary-3" v-if="page_data.error">{{ page_data.error }}</div>
  </div>
  <div id="basic-non-sticky-notification-packet-assign" class="toastify-content hidden flex flex-col sm:flex-row">
    <div class="flex items-center justify-around">
      <CheckCircleIcon class="w-6 h-6 text-theme-9 mx-auto" />
      <div class="font-medium text-black">{{ page_data.message }}</div>
    </div>
  </div>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-2">
      <h2 class="text-lg font-medium mr-auto">Final Polish Stock</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div class="text-sm font-bold mr-2 mt-5" v-if="page_data.packet_list.length > 0">Selected Stock : {{
          page_data.packet_list.length }}</div>
        <div class="flex items-center mt-2 ml-2">
          <div class="mr-2 flex items-center">
            <input id="remember-me" type="checkbox" v-model="page_data.check"
              class="form-check-input border mr-2 bg-white" />
            <label for="remember-me" class="text-md font-medium">Manual</label>
          </div>
        </div>
        <div class="w-full flex sm:w-auto mt-3 ml-2">
          <div class="w-56 relative text-gray-700 dark:text-gray-300 mr-4">
            <input type="text" v-model="page_data.search" @change="getPacketDetailsScan" autofocus ref="focusMe"
              :disabled="page_data.scan_disable == true" class="form-control w-56 box pr-10 placeholder-theme-13 py-1"
              placeholder="Scan Packet" />
            <SearchIcon class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" />
          </div>
          <button class="btn btn-primary btn-sm py-1 shadow-md mr-2" @click="add_stock_to_list">Add</button>
          <a class="btn btn-primary btn-sm shadow-md py-1 mr-2" href="javascript:;" data-toggle="modal"
            data-target="#new-process-modal" v-if="page_data.packet_list.length > 0" @click="getEmployees">Assign Packets</a>
          <router-link class="btn btn-primary btn-sm py-1 shadow-md mr-2" :to="{name: 'final-polish-stock-live'}" @click="getEmployees">Update Stock(Live)</router-link>
        </div>
      </div>
    </div>

    <!-- Begin: Scanned Lot Table -->
    <div class="intro-y box py-5 px-3 mt-5" v-if="page_data.packet_list.length > 0">
      <div class="overflow-auto">
        <table class="table w-full border">
          <thead>
            <tr>
              <th class="whitespace-nowrap">Pkt No</th>
              <th class="whitespace-nowrap">Stock No</th>
              <th class="whitespace-nowrap">Cr. Weight</th>
              <th class="whitespace-nowrap">Exp. Wt</th>
              <th class="whitespace-nowrap">Shape</th>
              <th class="whitespace-nowrap">Color</th>
              <th class="whitespace-nowrap">Purity</th>
              <th class="whitespace-nowrap">Length</th>
              <th class="whitespace-nowrap">Width</th>
              <th class="whitespace-nowrap">Height</th>
              <th class="whitespace-nowrap">Comment</th>
              <th class="whitespace-nowrap">Issue Comment</th>
              <th class="whitespace-nowrap"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(packet, index) in page_data.packet_list" :key="index"
              class="border hover:bg-gray-200 p-0 cursor-pointer">
              <td class="whitespace-nowrap">{{ packet.number }}</td>
              <td class="whitespace-nowrap">{{ packet.vepari_no ? packet.vepari_no : '-' }}</td>
              <td class="whitespace-nowrap">{{ packet.cr_weight ? packet.cr_weight : '-' }}</td>
              <td class="whitespace-nowrap">{{ packet.cr_polish_weight ? packet.cr_polish_weight : '-' }}</td>
              <td class="whitespace-nowrap">{{ packet.shape ? packet.shape : '-' }}</td>
              <td class="whitespace-nowrap">{{ packet.color ? packet.color : '-' }}</td>
              <td class="whitespace-nowrap">{{ packet.purity ? packet.purity : '-' }}</td>
              <td class="whitespace-nowrap">{{ packet.length ? $h.myFloat(packet.length ? packet.length : 0) : '-' }}</td>
              <td class="whitespace-nowrap">{{ packet.width ? $h.myFloat(packet.width ? packet.width : 0) : '-' }}</td>
              <td class="whitespace-nowrap">{{ packet.height ? $h.myFloat(packet.height ? packet.height : 0) : '-' }}</td>
              <td class="whitespace-nowrap">{{ packet.comment ? packet.comment : '-' }}</td>
              <td class="whitespace-nowrap" style="padding: 2px;"><input type="text" v-model="packet.new_comment"
                  class="form-control rounded-none" placeholder="Comment" aria-describedby="input-group-3" /></td>
              <td class="py-2 font-medium text-center">
                <div class="flex justify-start items-center p-0 m-0">
                  <a href="javascript:;" @click="removeItem(index)" class="btn btn-danger btn-sm mx-2">
                    <XIcon class="w-4 h-4" />
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- End: Scanned Lot Table -->
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div>
        <div class="grid grid-cols-12 gap-2">
            <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
                <div class="border rounded zoom-in">
                    <div class="box p-2">
                        <div class="flex items-center justify-between">
                            <!-- <ActivityIcon class="report-box__icon text-theme-10" /> -->
                            <div class="text-base text-gray-600">Total Available</div>
                            <div class="text-lg font-medium leading-5">{{ page_data.heading_total.total_available_pcs ? $h.myFloat(page_data.heading_total.total_available_pcs) : 0 }} ({{ page_data.heading_total.total_available_weight ? $h.myFloat(page_data.heading_total.total_available_weight) + ' Ct' : '0 Ct' }})</div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
                <div class="border rounded zoom-in">
                    <div class="box p-2">
                        <div class="flex items-center justify-between">
                            <!-- <ActivityIcon class="report-box__icon text-theme-10" /> -->
                            <div class="text-base text-gray-600">Non-Cert</div>
                            <div class="text-lg font-medium leading-5">{{ page_data.heading_total.total_loose_pcs ? $h.myFloat(page_data.heading_total.total_loose_pcs) : 0 }} ({{ page_data.heading_total.total_loose_weight ? $h.myFloat(page_data.heading_total.total_loose_weight) + ' Ct' : '0 Ct' }})</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
                <div class="border rounded zoom-in">
                    <div class="box p-2">
                        <div class="flex items-center justify-between">
                            <!-- <CheckCircleIcon class="report-box__icon text-theme-11" /> -->
                            <div class="text-base text-gray-600">Certified</div>
                            <div class="text-lg font-medium leading-5">{{ page_data.heading_total.total_certi_pcs ? $h.myFloat(page_data.heading_total.total_certi_pcs) : 0 }} ({{ page_data.heading_total.total_certi_weight ? $h.myFloat(page_data.heading_total.total_certi_weight) + ' Ct' : '0 Ct' }})</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-span-12 sm:col-span-6 lg:col-span-3 intro-y mt-2">
                <div class="border rounded zoom-in">
                    <div class="box p-2">
                        <div class="flex items-center justify-between">
                            <!-- <CheckCircleIcon class="report-box__icon text-theme-11" /> -->
                            <div class="text-base text-gray-600">Lab Issue</div>
                            <div class="text-lg font-medium leading-5">{{ page_data.heading_total.total_issue_pcs ? $h.myFloat(page_data.heading_total.total_issue_pcs) : 0 }} ({{ page_data.heading_total.total_issue_weight ? $h.myFloat(page_data.heading_total.total_issue_weight) + ' Ct' : '0 Ct' }})</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-12 gap-4 mt-2">
          <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
            <div>
              <input id="tabulator-html-filter-value" v-model="filter_data.stock_no" type="text"
                class="form-control 2xl:w-full" placeholder="Stock No" />
            </div>
          </div>
          <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
            <div>
              <TomSelect v-model="filter_data.kapan_id" :options="{
                placeholder: 'Select Kapan',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Kapan'
                  }
                }
              }" class="w-full" @change="initTabulator">
                <option value="0">Select Kapan</option>
                <option v-for="(item, index) in page_data.kapan_details" :key="index" :value="item.id">{{ item.lot_no }} ({{
                  item.voucher_no }})</option>
              </TomSelect>
            </div>
          </div>
          <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
            <div>
              <TomSelect v-model="filter_data.packet_type" :options="{
                placeholder: 'Select Packet Type',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Packet Type'
                  }
                }
              }" class="w-full" @change="initTabulator">
                <option value="0">Fresh Stock</option>
                <option :value="lab.id" v-for="(lab, index) in page_data.labs" :key="index">{{ lab.lab_code }}</option>
                <option value="non-cert">Non Certy</option>
              </TomSelect>
            </div>
          </div>
          <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-3">
            <div>
              <button class="btn btn-secondary mr-2" @click="resetFilters">
                Reset
              </button>
              <button class="btn btn-primary " @click="initTabulator">
                Generate
              </button>
            </div>
          </div>
          <div class="col-span-12 md:col-span-12 lg:col-span-4 xl:col-span-3">
            <div class="flex flex-wrap">

              <div class="text-sm font-bold mr-4">Total Stock: {{ page_data.total.total_packets ?
                page_data.total.total_packets : 0 }}</div>
              <div class="text-sm font-bold">Total WT(Ct): {{ page_data.total.total_weight ?
                $h.myFloat(page_data.total.total_weight) : 0 }} Ct</div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
  <!-- BEGIN: new Process Modal -->
  <div id="new-process-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <a data-dismiss="modal" href="javascript:;">
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="modal-body p-10 text-center">
          <div class="intro-y">
            <!-- <div id="vertical-form"> -->
            <div class="flex flex-col justify-between">
              <!-- BEGIN: Data List -->
              <div class="intro-y overflow-auto">
                <div class="flex">
                </div>
              </div>
              <div class="p-5">
                <div>
                  <div class="text-left">Select Stock Type</div>
                  <TomSelect v-model="process.lab_id" @change="validateManagerId" :options="{
                    placeholder: 'Select Stock Type'
                  }" class="w-full">
                    <option value="0">Select Stock Type</option>
                    <option :value="lab.id" v-for="(lab, index) in page_data.labs" :key="index">{{ lab.lab_code }}
                    </option>
                    <option value="non-cert">Non Certy</option>
                  </TomSelect>
                  <!-- <span class="text-primary-3 mb-2" v-if="errors.manager_id">{{ errors.manager_id[0] }}</span> -->
                </div>
                <div class="mt-3" v-if="process.lab_id != 0 && process.lab_id != 'non-cert'">
                  <div class="text-left">Select Job Party</div>
                    <TomSelect v-model="process.party_id" :options="{
            placeholder: 'Select Party'
          }" class="w-full">
                        <option value="0">Select Party</option>
                        <option v-for="(party,ind) in page_data.parties" :key="ind" :value="party.id">{{ party.firm_name }}</option>
                    </TomSelect>
                    <!-- <span class="text-primary-3 mb-2" v-if="errors.party_id">{{ errors.party_id[0] }}</span> -->
                </div>
              </div>
              <button type="button" @click="AssignProcess" :disabled="page_data.submit"
                class="btn btn-primary w-24 m-auto mt-5">Submit
                <LoadingIcon v-if="page_data.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: new Process Modal -->
</template>

<script>
/* eslint-disable */
import {
  BASE_URL
} from '@/constants/config'
import axios from 'axios'
import cash from 'cash-dom'
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  reactive,
  ref
} from 'vue'
import print from 'vue3-print-nb'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import Toastify from 'toastify-js'
import store from '@/store'
export default defineComponent({
  directives: {
    print
  },
  setup() {
    const user_details = computed(() => store.state.user.currentUser)
    const focusMe = ref()
    const swal = inject('$swal')
    const page_data = reactive({
      search: '',
      search_type: '',
      check: false,
      type: '',
      addlist: [],
      packet_list: [],
      addornot: false,
      employees: [],
      processes: [],
      parties: [],
      submit: false,
      managers: [],
      labs: [],
      message: '',
      error: '',

      total: [],
      heading_total: [],
      kapan_details: [],
    })
    // const success = toRef(page_data, 'success')
    const filter_data = reactive({
      kapan_id: '0',
      stock_no: '',
      packet_type: '0',
    })
    const process = reactive({

      lab_id: '0',
      eternals: '1',
      manager_id: '0',
      process_id: '0',
      employee_id: '0',
      transaction_type: '0',
      party_id: '0',
      type: ''
    })
    const tableRef = ref()
    const tabulator = ref()

    const initTabulator = async () => {

      let filter_params = {
        kapan_id: filter_data.kapan_id == '0' ? '' : window.btoa(filter_data.kapan_id),
        stock_no: filter_data.stock_no == '' ? '' : window.btoa(filter_data.stock_no),
        packet_type: window.btoa(filter_data.packet_type)
      }

      if(filter_data.packet_type == '0') {

        tabulator.value = new Tabulator(tableRef.value, {
          ajaxURL: BASE_URL + 'api/final-polish/lsc-gs',
          ajaxConfig: {
            method: "GET", //set request type to Position
            headers: {
              "Content-type": 'application/json; charset=utf-8', //set specific content type
              "Authorization": "Bearer " + localStorage.getItem("token")
            },
          },
          ajaxParams: filter_params,
          ajaxFiltering: true,
          ajaxSorting: true,
          printAsHtml: true,
          printStyled: true,
          headerSort: false,
          pagination: 'remote',
          paginationSize: 20,
          paginationSizeSelector: [20, 30, 40],
          layout: 'fitColumns',
          // responsiveLayout: 'collapse',
          placeholder: 'No matching records found',
          columns: [
            // {
            //       formatter: 'responsiveCollapse',
            //       width: 40,
            //       minWidth: 30,
            //       hozAlign: 'center',
            //       resizable: false,
            //       headerSort: false
            //   },

            // For HTML table
            {
              title: 'Packet No',
              responsive: 0,
              minWidth: 130,
              field: 'number',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                    <div class="whitespace-nowrap">${cell.getData().urgent == 1 ? '<span class="text-theme-6">' + cell.getData().number + '</span>' : cell.getData().number
                  }</div>
                  </div>`
              }
            },
            {
              title: 'Stock No',
              responsive: 0,
              field: 'type',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 130,
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${cell.getData().vepari_no
                  }</div>
                </div>`
              }
            },
            {
              title: 'Cr.Weight(Ct)',
              responsive: 0,
              field: 'cr_weight',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 130,
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                    <div class="whitespace-nowrap">${cell.getData().cr_weight
                  }</div>
                  </div>`
              }
            },
            {
              title: 'Exp. Wt',
              responsive: 0,
              field: 'cr_polish_weight',
              minWidth: 130,
              print: false,
              download: false
            },
            {
              title: 'Shp',
              field: 'shape',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 90,
              print: false,
              download: false
            },
            {
              title: 'Clr',
              field: 'color',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 90,
              print: false,
              download: false
            },
            {
              title: 'Prt',
              field: 'purity',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 90,
              print: false,
              download: false
            },
            {
              title: 'Ct',
              field: 'cut',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 90,
              print: false,
              download: false
            },
            {
              title: 'Pl',
              field: 'polish',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 90,
              print: false,
              download: false
            },
            {
              title: 'Sym',
              field: 'symmetry',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 90,
              print: false,
              download: false
            },
            {
              title: 'Fluc',
              field: 'fluerocent',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 90,
              print: false,
              download: false
            }
          ],
          renderComplete() {
            feather.replace({
              'stroke-width': 1.5
            })
          }
        })
      } else {

        tabulator.value = new Tabulator(tableRef.value, {
          ajaxURL: BASE_URL + 'api/final-polish/lsc-gs',
          ajaxConfig: {
            method: "GET", //set request type to Position
            headers: {
              "Content-type": 'application/json; charset=utf-8', //set specific content type
              "Authorization": "Bearer " + localStorage.getItem("token")
            },
          },
          ajaxParams: filter_params,
          ajaxFiltering: true,
          ajaxSorting: true,
          printAsHtml: true,
          printStyled: true,
          headerSort: false,
          pagination: 'remote',
          paginationSize: 20,
          paginationSizeSelector: [20, 30, 40],
          layout: 'fitColumns',
          // responsiveLayout: 'collapse',
          placeholder: 'No matching records found',
          columns: [
            // {
            //       formatter: 'responsiveCollapse',
            //       width: 40,
            //       minWidth: 30,
            //       hozAlign: 'center',
            //       resizable: false,
            //       headerSort: false
            //   },

            // For HTML table
            {
              title: 'Packet No',
              responsive: 0,
              minWidth: 130,
              field: 'number',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                    <div class="whitespace-nowrap">${cell.getData().urgent == 1 ? '<span class="text-theme-6">' + cell.getData().number + '</span>' : cell.getData().number
                  }</div>
                  </div>`
              }
            },
            {
              title: 'Stock No',
              responsive: 0,
              field: 'type',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 130,
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${cell.getData().vepari_no
                  }</div>
                </div>`
              }
            },
            {
              title: 'Cr.Weight(Ct)',
              responsive: 0,
              field: 'cr_weight',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 130,
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                    <div class="whitespace-nowrap">${cell.getData().cr_weight
                  }</div>
                  </div>`
              }
            },
            {
              title: 'Exp. Wt',
              responsive: 0,
              field: 'cr_polish_weight',
              minWidth: 130,
              print: false,
              download: false
            },
            {
              title: 'Shp',
              field: 'shape',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 90,
              print: false,
              download: false
            },
            {
              title: 'Clr',
              field: 'color',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 90,
              print: false,
              download: false
            },
            {
              title: 'Prt',
              field: 'purity',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 90,
              print: false,
              download: false
            },
            {
              title: 'Ct',
              field: 'cut',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 90,
              print: false,
              download: false
            },
            {
              title: 'Pl',
              field: 'polish',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 90,
              print: false,
              download: false
            },
            {
              title: 'Sym',
              field: 'symmetry',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 90,
              print: false,
              download: false
            },
            {
              title: 'Fluc',
              field: 'fluerocent',
              hozAlign: 'center',
              vertAlign: 'middle',
              minWidth: 90,
              print: false,
              download: false
            }
          ],
          renderComplete() {
            feather.replace({
              'stroke-width': 1.5
            })
          }
        })
      }

      let promise = axios({
        url: "api/final-polish/lsc-gst",
        method: "post",
        data: filter_params,
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          console.log(data);
          page_data.total = data.total
        })
        .catch(_error => {
          page_data.total = []
        });
    }

    const get_heading_total = () => {
      // lsc-gsth
      let promise = axios({
        url: "api/final-polish/lsc-gsth",
        method: "post",
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        config: {
          headers: {
            Accept: "application/json"
          }
        }
      });
      return promise
        .then(result => result.data)
        .then(data => {
          console.log(data);
          page_data.heading_total = data.total
        })
        .catch(_error => {
          page_data.heading_total = []
        })
    }

    const add_stock_to_list = () => {
      if (page_data.search != '') {
        if (page_data.addlist.length <= 0) {
          verify_stock()
        } else {
          for (let i = 0; i < page_data.packet_list.length; i++) {
            if (page_data.packet_list[i].number == page_data.search || page_data.packet_list[i].barnum == page_data.search || page_data.packet_list[i].vepari_no == page_data.search) {
              swal("Warning", "Scanned stock already in list", "warning")
              page_data.search = ''
              return;
            } else {
              page_data.addornot = false
            }

          }
          if (page_data.addornot == false) {
            verify_stock()
          }
        }
      }
    }

    const verify_stock = () => {
      const params = {
        type: window.btoa(page_data.search_type),
        number: window.btoa(page_data.search),
        // packet_type: window.btoa(filter_data.packet_type)
      }
      axios({
        url: 'api/final-polish/lsc-sap',
        method: 'post',
        baseURL: BASE_URL,
        data: params,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            'Accept': 'application/json'
          }
        }
      }).then((res) => {
        const index = ref(page_data.addlist.length)
        page_data.addlist[index.value] = res.data.packet.number
        page_data.packet_list[index.value] = res.data.packet
        page_data.search_type = (res.data.packet.lab == null ? 0 : (res.data.packet.lab == 0 ? "lab-cert" : res.data.packet.lab))
        page_data.search = ''
        focusMe.value.focus()
        // page_data.checklist[page_data.addlist[index.value].id] = true
      }).catch((err) => {
        page_data.error = err.response.data.error
        page_data.search = ''
        focusMe.value.focus()
        console.warn(page_data.error)
        setTimeout(() => {
          basicNonStickyNotificationPacket()
        }, 100)
        // setTimeout(() => {
        //   page_data.search = ''
        // }, 1000);
        setTimeout(() => {
          page_data.error = ''
        }, 3000);

      })
    }

    const removeItem = (id) => {

      page_data.addlist.splice(id, 1);
      page_data.packet_list.splice(id, 1);

      if (page_data.addlist.length <= 0) {
        page_data.search_type = ''
      }
    }

    const getLotDetails = () => {
      axios({
        url: 'api/master/dp_lot_details',
        method: 'post',
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            'Accept': 'application/json'
          }
        }
      }).then((res) => {
        page_data.kapan_details = res.data.items
      })
    }

    const getTransferCheck = () => {
      axios({
        url: '/user/transferManager',
        method: 'get',
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            'Accept': 'application/json'
          }
        }
      }).then((res) => {
        page_data.managers = res.data.managers
      })
    }

    // const AssignProcess = () => {

    //   if (process.lab_id == "0") {
    //     swal("Warning!", "Please Select Type, First !", "warning");
    //   } else if (process.lab_id == "non-cert") {
    //     swal("Warning!", "Please Select Type, First !", "warning");
    //   } else {

    //     const params = {
    //       stock_arr: page_data.packet_list,
    //       number: page_data.addlist,
    //       lab_id: window.btoa(process.lab_id),
    //       packet_type: window.btoa(filter_data)
    //     }
    //     page_data.submit = true
    //     axios({
    //       url: 'api/final-polish/lsc-ust',
    //       method: 'post',
    //       baseURL: BASE_URL,
    //       data: params,
    //       headers: {
    //         Authorization: 'Bearer ' + localStorage.getItem('token')
    //       },
    //       config: {
    //         headers: {
    //           'Accept': 'application/json'
    //         }
    //       }
    //     }).then((res) => {
    //       page_data.message = res.data.success
    //       page_data.submit = false
    //       initTabulator()
    //       setTimeout(() => {
    //         basicNonStickyNotificationPacketAssign()
    //       }, 100);
    //       cash('#new-process-modal').modal('hide')
    //       page_data.addlist = []
    //       page_data.packet_list = []
    //       page_data.search_type = ''
    //       process.lab_id = '0'
    //       setTimeout(() => {
    //         page_data.message = ''
    //       }, 3000)
    //     }).catch((err) => {
    //       page_data.submit = false
    //       swal("Warning!", err.response.data.error, "warning");
    //     })
    //   }
    // }

    const AssignProcess = () => {

      if (process.lab_id == "0") {
        swal("Warning!", "Please Select Type, First !", "warning");
      } else if (process.lab_id == "non-cert") {
        const params = {
          stock_arr: page_data.packet_list,
          number: page_data.addlist,
          lab_id: window.btoa(process.lab_id),
          packet_type: window.btoa(filter_data)
        }
        page_data.submit = true
        axios({
          url: 'api/final-polish/lsc-ust',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          config: {
            headers: {
              'Accept': 'application/json'
            }
          }
        }).then((res) => {
          page_data.message = res.data.success
          page_data.submit = false
          initTabulator()
          setTimeout(() => {
            basicNonStickyNotificationPacketAssign()
          }, 100);
          cash('#new-process-modal').modal('hide')
          page_data.addlist = []
          page_data.packet_list = []
          page_data.search_type = ''
          process.lab_id = '0'
          setTimeout(() => {
            page_data.message = ''
          }, 3000)
        }).catch((err) => {
          page_data.submit = false
          swal("Warning!", err.response.data.error, "warning");
        })
      } else {

        if(process.party_id == "0") {

          const params = {
            stock_arr: page_data.packet_list,
            number: page_data.addlist,
            lab_id: window.btoa(process.lab_id),
            packet_type: window.btoa(filter_data)
          }
          page_data.submit = true
          axios({
            url: 'api/final-polish/lsc-ust',
            method: 'post',
            baseURL: BASE_URL,
            data: params,
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            },
            config: {
              headers: {
                'Accept': 'application/json'
              }
            }
          }).then((res) => {
            page_data.message = res.data.success
            page_data.submit = false
            initTabulator()
            setTimeout(() => {
              basicNonStickyNotificationPacketAssign()
            }, 100);
            cash('#new-process-modal').modal('hide')
            page_data.addlist = []
            page_data.packet_list = []
            page_data.search_type = ''
            process.lab_id = '0'
            setTimeout(() => {
              page_data.message = ''
            }, 3000)
          }).catch((err) => {
            page_data.submit = false
            swal("Warning!", err.response.data.error, "warning");
          })

        } else {

          const params = {
                stock_arr: page_data.packet_list,
                number: page_data.addlist,
                externals: window.btoa(process.eternals),
                lab_id: process.lab_id == '0' ? '' : window.btoa(process.lab_id),
                process_id: process.process_id == '0' ? '' : window.btoa(process.process_id),
                employee_id: process.employee_id == '0' ? '' : window.btoa(process.employee_id),
                manager_id: process.manager_id == '0' ? '' : window.btoa(process.manager_id),
                party_id: process.party_id == '0' ? '' : window.btoa(process.party_id),
                transaction_type: window.btoa(process.transaction_type),
            }
            page_data.submit = true
            axios({
              url: 'api/process/manager_multiple_issue',
                method: 'post',
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
                config: {
                    headers: {
                        'Accept': 'application/json'
                    }
                }
            }).then((res) => {
              if(res.data.jangad_id) {
                let url = BASE_URL + 'jangad' + '?id=' + btoa(res.data.jangad_id)
                window.open(url, '_blank');
              }
                page_data.message = res.data.success
                page_data.submit = false
                initTabulator()
                setTimeout(() => {
                    basicNonStickyNotificationPacketAssign()
                }, 100);
                cash('#new-process-modal').modal('hide')
                page_data.addlist = []
                page_data.packet_list = []
                page_data.search_type = ''
                process.party_id = '0'
                setTimeout(() => {
                    page_data.message = ''
                }, 3000)
            }).catch((err) => {
                page_data.submit = false
                swal("Warning!", err.response.data.error, "warning");
            })

        }
      }
        }

    const getEmployees = () => {

      process.lab_id = String(page_data.search_type)
      const params = {
        process_type: window.btoa(page_data.search_type)
      }
      axios({
          url: 'api/final-polish/lsc-lab-party',
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          config: {
            headers: {
              'Accept': 'application/json'
            }
          }
        }).then((res) => {
          page_data.parties = res.data.items
          process.process_id = String(res.data.lab_id)
          process.party_id = '0'
        })
    }

    const getAllManager = () => {
      axios({
        url: 'api/master/dp_managers',
        method: 'post',
        baseURL: BASE_URL,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token')
        },
        config: {
          headers: {
            'Accept': 'application/json'
          }
        }
      }).then((res) => {
        page_data.managers = res.data.items
        process.manager_id = '0'
      })
    }

    const getDiamontData = () => {
      axios({
        url: 'api/master/get_all_parameters',
        method: 'post',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept': 'application/json' } }
      }).then((res) => {
        page_data.labs = res.data.lab
      })
    }

    const validateManagerId = () => {
      if (user_details.value.account_id == process.manager_id) {
        process.manager_id = '0'
        swal("Warning!", "Self Transfre is Not Allowed !", "warning");
      }
    }

    const getPacketDetailsScan = () => {
      if (!page_data.check && page_data.search) {
        add_stock_to_list();
      }
    }

    const resetFilters = () => {
      filter_data.packet_type = '0'
      filter_data.kapan_id = '0'
      filter_data.stock_no = ''

      initTabulator()
    }

    const basicNonStickyNotificationPacket = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
    const basicNonStickyNotificationPacketAssign = () => {
      Toastify({
        node: cash('#basic-non-sticky-notification-packet-assign')
          .clone()
          .removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }

    onMounted(() => {
      initTabulator()
      get_heading_total()
      getAllManager()
      getLotDetails()
      getDiamontData()
      focusMe.value.focus()
    })

    return {
      page_data,
      filter_data,
      focusMe,
      user_details,
      process,
      getPacketDetailsScan,
      tableRef,
      add_stock_to_list,
      removeItem,
      getTransferCheck,
      getEmployees,
      getAllManager,
      AssignProcess,
      initTabulator,
      validateManagerId,
      resetFilters,
      basicNonStickyNotificationPacketAssign,
      basicNonStickyNotificationPacket,
    }
  }
})
</script>
