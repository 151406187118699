<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Assortment Stocks</h2>
        <!-- <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <router-link
          :to="{ name: 'add-issue-packet' }"
          tag="button"
           class="btn btn-primary shadow-md mr-2">
           New Issue
          </router-link>
      </div> -->
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
        <div>
            <div class="grid grid-cols-12 gap-5">
                <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
                    <div>
                      <label for="update-profile-form-6" class="form-label pt-2">Select Assortment</label>
                      <TomSelect v-model="screenData.assortment_id" multiple :options="{
                  placeholder: 'Select Assortment',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }" class="w-full">
                        <!-- <option value="0">Select Assortment</option> -->
                        <option v-for="item in screenData.assort_arr" :key="item.id" :value="item.id">{{item.assortment_name}}</option>
                    </TomSelect>
                    </div>
                </div>
                <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2">
                    <div>
                      <label for="update-profile-form-6" class="form-label pt-2">Select Kapan</label>
                      <TomSelect v-model="screenData.kapan_id" multiple :options="{
                  placeholder: 'Select Kapan',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }" class="w-full">
                        <option value="0">Select Kapan</option>
                        <option v-for="item in screenData.kapan_arr" :key="item.id" :value="item.id">{{item.lot_no}} ({{item.voucher_no}})</option>
                    </TomSelect>
                    </div>
                </div>
                <div class="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-3">
                    <div class="mt-9">
                        <button class="btn btn-secondary mr-2" @click="resetFilters">
                            Reset
                        </button>
                        <button class="btn btn-primary " @click="initTabulator">
                            Generate
                        </button>
                    </div>
                </div>
                <div class="col-span-12 md:col-span-12 lg:col-span-4 xl:col-span-3">
                    <div class="flex flex-wrap">
                        <div class="text-sm font-bold">Total WT(Ct): {{ screenData.total.total_assort_weight ? $h.myFloat(screenData.total.total_assort_weight) : 0 }} Ct</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overflow-x-auto scrollbar-hidden">
            <div id="tabulator" ref="tableRef" class="mt-5"></div>
        </div>
    </div>
    <!-- END: HTML Table Data -->
</div>
</template>

<script>
import {
    defineComponent,
    computed,
    ref,
    reactive,
    onMounted,
    inject
} from 'vue'
import xlsx from 'xlsx'
import {
    useStore
} from '@/store'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import VueRouter from 'vue-router'
import {
    useRouter,
    useRoute
} from 'vue-router'
import {
    BASE_URL
} from '@/constants/config.js'
import moment from 'moment'

export default defineComponent({
    setup() {
        const router = useRouter()
        const store = useStore()
        const route = useRoute()
        const tableRef = ref()
        const tabulator = ref()
        const swal = inject('$swal')
        const userDetails = computed(() => store.state.user.currentUser)

        const screenData = reactive({
            total: [],
            kapan_arr: [],
            assortment_arr: [],
            kapan_id: [],
            assortment_id: [],
        })

        const initTabulator = () => {

            let _this = this;
            tabulator.value = new Tabulator(tableRef.value, {
                ajaxURL: BASE_URL + 'api/purchase/assortment_stocks',
                ajaxConfig: {
                    method: "GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                ajaxParams: {
                    kapan_id: screenData.kapan_id.length == 0 ? '' : window.btoa(screenData.kapan_id),
                    assortment_id: screenData.assortment_id.length == 0 ? '' : window.btoa(screenData.assortment_id)
                },
                ajaxFiltering: true,
                ajaxSorting: true,
                printAsHtml: true,
                printStyled: true,
                pagination: 'remote',
                paginationSize: 20,
                paginationSizeSelector: [20, 30, 40],
                layout: 'fitColumns',
                responsiveLayout: false,
                tooltipsHeader: true,
                headerSort: false,
                placeholder: 'No matching records found',
                columns: [

                    // For HTML table
                    {
                        title: 'Kapan No',
                        minWidth: 200,
                        responsive: 0,
                        field: 'transaction_code',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                        formatter(cell) {
                            return (cell.getData().kapan.lot_no ? cell.getData().kapan.lot_no : "-") + ' ( ' + cell.getData().kapan.voucher_no + ' ) '
                        }
                    },
                    {
                        title: 'Assortment Name',
                        field: 'pieces',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                        formatter(cell) {
                            return (cell.getData().assortment.assortment_name ? cell.getData().assortment.assortment_name : "-")
                        }
                    },
                    {
                        title: 'Weight (Ct)',
                        field: 'issue_weight',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                        formatter(cell) {
                            return `<div class="text-md">
                <p class="font-12 font-bold text-md">` + parseFloat(cell.getData().assortment_weight).toFixed(2) + ` ct </p>
              </div>`;
                        }
                    }

                ],
                renderComplete() {
                    feather.replace({
                        'stroke-width': 1.5
                    })
                }
            })

            let promise = axios({
                url: "api/purchase/assortment_total",
                method: "post",
                data: {
                  kapan_id: screenData.kapan_id.length == 0 ? '' : window.btoa(screenData.kapan_id),
                    assortment_id: screenData.assortment_id.length == 0 ? '' : window.btoa(screenData.assortment_id)
                },
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    screenData.total = data.total

                    console.log(screenData.total)
                })
                .catch(_error => {
                    screenData.total = []
                });
        }

        const getAssortmentDetails = () => {
          let api_url = 'api/master/dp_rejection_assortment'

          let promise = axios({
              url: api_url,
              method: "post",
              baseURL: BASE_URL,
              headers: {
                  Authorization: "Bearer " + localStorage.getItem("token")
              },
              config: {
                  headers: {
                      Accept: "application/json"
                  }
              }
          });
          return promise
              .then(result => result.data)
              .then(data => {
                  screenData.assort_arr = data.items
                })
                .catch(_error => {
                screenData.assort_arr = []
              });

        }

        const getKapanDetails = () => {
          let api_url = 'api/master/dp_lot_details'

          let promise = axios({
              url: api_url,
              method: "post",
              baseURL: BASE_URL,
              headers: {
                  Authorization: "Bearer " + localStorage.getItem("token")
              },
              config: {
                  headers: {
                      Accept: "application/json"
                  }
              }
          });
          return promise
              .then(result => result.data)
              .then(data => {
                  screenData.kapan_arr = data.items
                })
                .catch(_error => {
                screenData.kapan_arr = []
              });

        }
        const resetFilters = () => {
          screenData.kapan_id = []
          screenData.assortment_id = []

          initTabulator()
        }
        // Redraw table onresize
        const reInitOnResizeWindow = () => {
            window.addEventListener('resize', () => {
                tabulator.value.redraw()
                feather.replace({
                    'stroke-width': 1.5
                })
            })
        }

        // Export
        const onExportCsv = () => {
            tabulator.value.download('csv', 'data.csv')
        }

        const onExportJson = () => {
            tabulator.value.download('json', 'data.json')
        }

        const onExportXlsx = () => {
            const win = window
            win.XLSX = xlsx
            tabulator.value.download('xlsx', 'buyer_list.xlsx', {
                sheetName: 'Buyers'
            })
        }

        const onExportHtml = () => {
            tabulator.value.download('html', 'data.html', {
                style: true
            })
        }

        // Print
        const onPrint = () => {
            tabulator.value.print()
        }

        onMounted(() => {
          getKapanDetails()
          initTabulator()
            reInitOnResizeWindow()
        })

        return {
            tableRef,
            screenData,
            userDetails,
            onExportCsv,
            onExportJson,
            onExportXlsx,
            onExportHtml,
            initTabulator,
            resetFilters,
            onPrint,
        }

    }
})
</script>
