<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Packet Issue Transactions</h2>
        <!-- <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <router-link
          :to="{ name: 'add-issue-packet' }"
          tag="button"
           class="btn btn-primary shadow-md mr-2">
           New Issue
          </router-link>
      </div> -->
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
        <div class="flex justify-between flex-col sm:flex-row sm:items-end xl:items-start">
            <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
                <div class="sm:flex items-center sm:mr-4">
                    <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2">Field</label>
                    <select id="tabulator-html-filter-field" v-model="filter.field" class="form-select w-full sm:w-32 2xl:w-full mt-2 sm:mt-0 sm:w-auto">
                        <option value="lot_no">Lot No</option>
                        <option value="number">Packet No</option>
                    </select>
                </div>
                <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                    <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2">Type</label>
                    <select id="tabulator-html-filter-type" v-model="filter.type" class="form-select w-full mt-2 sm:mt-0 sm:w-auto">
                        <option value="like" selected>like</option>
                        <option value="=">=</option>
                        <option value="<">&lt;</option>
                        <option value="<=">&lt;=</option>
                        <option value=">">></option>
                        <option value=">=">>=</option>
                        <option value="!=">!=</option>
                    </select>
                </div>
                <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                    <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2">Value</label>
                    <input id="tabulator-html-filter-value" v-model="filter.value" type="text" class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0" placeholder="Search..." />
                </div>
                <div class="mt-2 xl:mt-0">
                    <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full sm:w-16" @click="onFilter">
                        Go
                    </button>
                    <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1" @click="onResetFilter">
                        Reset
                    </button>
                </div>
            </form>
            <div>
              <div class="mx-2 flex items-center" v-if="[1, 8].includes(userDetails.user_role)">
              <label for="update-profile-form-6" class="form-label pt-2">With Manager Records</label>
              <input
                    type="checkbox"
                    v-model="screenData.manager_records" @change="initTabulator"
                    class="form-check-switch ml-3"
                  />
            </div>
            </div>
        </div>
        <div class="flex mt-5 sm:mt-0">
            <div class="mx-2">
                <div>
                    <label for="update-profile-form-6" class="form-label pt-2">Select Process</label>
                    <TomSelect v-model="screenData.process_id" @change="getEmployeeDetails('update')" :options="{
                  placeholder: 'Select Process',
                  loadingClass: 'loading',
                  plugins: {
                    dropdown_header: {
                      title: 'Process'
                    }
                  }
                }" class="w-48">
                        <option value="0">Select Process</option>
                        <option value="manager_transfer">Manager Transfer</option>
                        <option v-for="item in screenData.process_items" :key="item.id" :value="item.id">{{item.process_name}} ( {{ item.process_short_code ? item.process_short_code : '-' }} )</option>
                    </TomSelect>
                </div>
            </div>
            <div class="mx-2" v-if="[1,2,8].includes(userDetails.user_role) ">
                <div>
                    <label for="update-profile-form-6" class="form-label pt-2">Employee Type</label>
                    <TomSelect v-model="screenData.employee_type" @change="getEmployeeDetails" :options="{
                placeholder: 'Select Employee Type',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Employee Type'
                  }
                }
              }" class="w-40">
                        <option value="1">Employee</option>
                        <!-- <option value="2">Planner / Checker</option> -->
                        <option value="3">Job Party</option>
                    </TomSelect>
                </div>
            </div>
            <div class="mx-2" v-if="[1,2,8].includes(userDetails.user_role) ">
                <div>
                    <label for="update-profile-form-6" class="form-label pt-2">Select Employee</label>
                    <TomSelect v-model="screenData.employee_id" @change="initTabulator" :options="{
                placeholder: 'Select Employee',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Employee'
                  }
                }
              }" class="w-48">
                        <option value="0">Select Employee</option>
                        <option v-for="item in screenData.employees" :key="item.id" :value="item.id">{{item.first_name}} {{item.last_name}} ( {{ item.process_short_code ? item.short_code : '-' }} )</option>
                    </TomSelect>
                </div>
            </div>
            <div class="mx-2" v-if="[1,2,8].includes(userDetails.user_role) ">
                <div>
                    <label for="update-profile-form-6" class="form-label pt-2">Select Employee</label>
                    <TomSelect v-model="screenData.status" @change="initTabulator" :options="{
                placeholder: 'Select Employee',
                loadingClass: 'loading',
                plugins: {
                  dropdown_header: {
                    title: 'Employee'
                  }
                }
              }" class="w-48">
                        <option value="0">Select Status</option>
                        <option value="1">Pending</option>
                        <option value="2">Accepted</option>
                        <option value="3">In Process</option>
                        <option value="4">Returned</option>
                        <option value="5">Cancelled</option>
                    </TomSelect>
                </div>
            </div>
        </div>
        <div class="overflow-x-auto scrollbar-hidden">
            <div id="tabulator" ref="tableRef" class="mt-5"></div>
        </div>
    </div>
    <!-- END: HTML Table Data -->
</div>
</template>

<script>
import {
    defineComponent,
    computed,
    ref,
    reactive,
    onMounted,
    inject
} from 'vue'
import xlsx from 'xlsx'
import {
    useStore
} from '@/store'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import VueRouter from 'vue-router'
import {
    useRouter,
    useRoute
} from 'vue-router'
import {
    BASE_URL
} from '@/constants/config.js'
import moment from 'moment'

export default defineComponent({
    setup() {
        const router = useRouter()
        const store = useStore()
        const route = useRoute()
        const tableRef = ref()
        const tabulator = ref()
        const filter = reactive({
            field: 'lot_no',
            type: 'like',
            value: ''
        })

        const swal = inject('$swal')
        const userDetails = computed(() => store.state.user.currentUser)

        const screenData = reactive({
            issuer: '',
            status: '0',
            employee_type: '3',
            employee_id: '',
            process_id: '0',
            employees: [],
            process_items: [],
            emp_hit: 1,
            manager_records: false
        })

        const initTabulator = () => {

          localStorage.setItem('transaction_filters', JSON.stringify({
            employee_type: screenData.employee_type,
            employee_id: screenData.employee_id,
            process_id: screenData.process_id,
            status: screenData.status,
          }))
            let _this = this;

            if(screenData.manager_records == false) {

              tabulator.value = new Tabulator(tableRef.value, {
                  ajaxURL: BASE_URL + 'api/purchase/packet_transactions_by_user',
                  ajaxConfig: {
                      method: "GET", //set request type to Position
                      headers: {
                          "Content-type": 'application/json; charset=utf-8', //set specific content type
                          "Authorization": "Bearer " + localStorage.getItem("token")
                      },
                  },
                  ajaxParams: {
                      employee_type: screenData.employee_type == '0' ? '' : window.btoa(screenData.employee_type),
                      employee_id: screenData.employee_id == '0' ? '' : window.btoa(screenData.employee_id),
                      process_id: screenData.process_id == '0' ? '' : window.btoa(screenData.process_id),
                      status: screenData.status == '0' ? '' : window.btoa(screenData.status),
                      manager_records: (screenData.manager_records == true ? 1 : "")
                  },
                    ajaxFiltering: true,
                    ajaxSorting: true,
                    printAsHtml: true,
                    printStyled: true,
                    pagination: 'remote',
                    paginationSize: 50,
                    paginationSizeSelector: [50, 100, 200],
                    layout: 'fitColumns',
                    responsiveLayout: false,
                    tooltipsHeader: true,
                    headerSort: false,
                    placeholder: 'No matching records found',
                    columns: [

                        // For HTML table
                        {
                            title: 'Transaction No',
                            minWidth: 200,
                            responsive: 0,
                            field: 'transaction_code',
                            vertAlign: 'middle',
                            print: false,
                            download: true,

                            formatter(cell) {

                              if (cell.getData().issue_by == userDetails.value.id) {
                                const a = cash(`<div><i data-feather="arrow-up-right" class="w-6 h-6 font-bold text-theme-9 mr-1"></i>
                 ${
                  cell.getData().transaction_code
                 }
                </div>`)
                                cash(a).on('click', function () {
                                    router.push({
                                        'path': '/packets/transaction/' + window.btoa(cell.getData().id)
                                    })
                                })
                                return a[0]
                              } else if (cell.getData().manager_id == userDetails.value.account_id) {
                                const a = cash(`<div><i data-feather="arrow-down-left" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>
                 ${
                  cell.getData().transaction_code
                 }
                </div>`)
                                cash(a).on('click', function () {
                                    router.push({
                                        'path': '/packets/transaction/' + window.btoa(cell.getData().id)
                                    })
                                })
                                return a[0]
                              } else {
                                const a = cash(`<div><i data-feather="arrow-up-right" class="w-6 h-6 font-bold text-theme-9 mr-1"></i>
                 ${
                  cell.getData().transaction_code
                 }
                </div>`)
                                cash(a).on('click', function () {
                                    router.push({
                                        'path': '/packets/transaction/' + window.btoa(cell.getData().id)
                                    })
                                })
                                return a[0]
                              }

                            }
                        },
                        {
                          title: 'Pieces',
                          field: 'pieces',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                      },
                      {
                          title: 'Issue Weight',
                          field: 'issue_weight',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          formatter(cell) {
                              return `<div class="text-md">
                  <p class="font-12 font-bold text-md">` + Number(cell.getData().issue_weight).toFixed(4) + ` ct </p>
                </div>`;
                          }
                      },
                      {

                          title: 'Manager/Worker',
                          field: 'karigar_first_name',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          formatter(cell) {
                              if (cell.getData().issue_by == userDetails.value.id) {

                                  if (cell.getData().transaction_type == 1) {
                                      return `<div class="">` + cell.getData().issue_manager_first_name + ' ' + cell.getData().issue_manager_last_name + `<br>
                                        <p class="text-theme-9 font-12 font-bold">` + 'Transfer' + `</p>
                                      </div>`;
                                  } else if (cell.getData().transaction_type == 2) {
                                      if (cell.getData().process_type == 1 || cell.getData().process_type == 2 || cell.getData().process_type == 5 || cell.getData().process_type == 6 || cell.getData().process_type == 7 || cell.getData().process_type == 8 || cell.getData().process_type == 9) {

                                          if (cell.getData().employee_role == 7) {
                                              return `<div class="">` + cell.getData().karigar_first_name + ' ' + cell.getData().karigar_last_name + `<br>
                                                  <p class="text-theme-9 font-12 font-bold">` + cell.getData().process_name + `</p>
                                              </div>`;
                                          } else if (cell.getData().employee_role == 4) {
                                              return `<div class="">` + cell.getData().pp_first_name + ' ' + cell.getData().pp_last_name + `<br>
                                                  <p class="text-theme-9 font-12 font-bold">` + cell.getData().process_name + `</p>
                                              </div>`;
                                          }
                                      } else if (cell.getData().process_type == 3 || cell.getData().process_type == 4) {
                                          return `<div class="">` + cell.getData().planner_first_name + ' ' + cell.getData().planner_last_name + `<br>
                                              <p class="text-theme-9 font-12 font-bold">` + cell.getData().process_name + `</p>
                                          </div>`;
                                      }
                                  } else if (cell.getData().transaction_type == 4) {
                                      return `<div class="">` + cell.getData().issue_manager_first_name + ' ' + cell.getData().issue_manager_last_name + `<br>
                                        <p class="text-theme-9 font-12 font-bold">` + 'Job Done' + `</p>
                                      </div>`;
                                  } else if (cell.getData().transaction_type == 3) {
                                      return `<div class="">` + cell.getData().issue_manager_first_name + ' ' + cell.getData().issue_manager_last_name + `<br>
                                          <p class="text-theme-9 font-12 font-bold">` + 'Process Done' + `</p>
                                        </div>`;
                                  }

                              } else if (cell.getData().manager_id == userDetails.value.account_id) {
                                if (cell.getData().transaction_type == 1) {
                                      return `<div class="">` + cell.getData().manager_first_name + ' ' + cell.getData().manager_last_name + `<br>
                                        <p class="text-theme-9 font-12 font-bold">` + 'Transfer' + `</p>
                                      </div>`;
                                  } else if (cell.getData().transaction_type == 2) {
                                      return `<div class="">` + cell.getData().manager_first_name + ' ' + cell.getData().manager_last_name + `<br>
                                        <p class="text-theme-9 font-12 font-bold">` + 'Transfer' + `</p>
                                      </div>`;
                                  } else if (cell.getData().transaction_type == 4) {
                                      return `<div class="">` + cell.getData().manager_first_name + ' ' + cell.getData().manager_first_name + `<br>
                                        <p class="text-theme-9 font-12 font-bold">` + 'Job Done' + `</p>
                                      </div>`;
                                  } else if (cell.getData().transaction_type == 3) {
                                      return `<div class="">` + cell.getData().manager_first_name + ' ' + cell.getData().manager_first_name + `<br>
                                      <p class="text-theme-9 font-12 font-bold">` + 'Process Done' + `</p>
                                    </div>`;
                                  }
                              } else {
                                if (cell.getData().transaction_type == 1) {
                                      return `<div class="">` + cell.getData().issue_manager_first_name + ' ' + cell.getData().issue_manager_last_name + `<br>
                                        <p class="text-theme-9 font-12 font-bold">` + 'Transfer' + `</p>
                                      </div>`;
                                  } else if (cell.getData().transaction_type == 2) {
                                      if (cell.getData().process_type == 1 || cell.getData().process_type == 2 || cell.getData().process_type == 5 || cell.getData().process_type == 6 || cell.getData().process_type == 7 || cell.getData().process_type == 8 || cell.getData().process_type == 9) {

                                          if (cell.getData().employee_role == 7) {
                                              return `<div class="">` + cell.getData().karigar_first_name + ' ' + cell.getData().karigar_last_name + `<br>
                                                  <p class="text-theme-9 font-12 font-bold">` + cell.getData().process_name + `</p>
                                              </div>`;
                                          } else if (cell.getData().employee_role == 4) {
                                              return `<div class="">` + cell.getData().pp_first_name + ' ' + cell.getData().pp_last_name + `<br>
                                                  <p class="text-theme-9 font-12 font-bold">` + cell.getData().process_name + `</p>
                                              </div>`;
                                          }
                                      } else if (cell.getData().process_type == 3 || cell.getData().process_type == 4) {
                                          return `<div class="">` + cell.getData().planner_first_name + ' ' + cell.getData().planner_last_name + `<br>
                                              <p class="text-theme-9 font-12 font-bold">` + cell.getData().process_name + `</p>
                                          </div>`;
                                      }
                                  } else if (cell.getData().transaction_type == 4) {
                                      return `<div class="">` + cell.getData().issue_manager_first_name + ' ' + cell.getData().issue_manager_last_name + `<br>
                                        <p class="text-theme-9 font-12 font-bold">` + 'Job Done' + `</p>
                                      </div>`;
                                  } else if (cell.getData().transaction_type == 3) {
                                      return `<div class="">` + cell.getData().issue_manager_first_name + ' ' + cell.getData().issue_manager_last_name + `<br>
                                        <p class="text-theme-9 font-12 font-bold">` + 'Process Done' + `</p>
                                      </div>`;
                                  }
                              }
                          }
                      },
                      {
                          title: 'Issue/ Receive Date',
                          field: 'created_at',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          download: true,
                          formatter(cell) {
                              return moment(cell.getData().created_at).format('D/M/YYYY hh:mm:ss') +  '<br>' + (cell.getData().receive_date ? moment(cell.getData().receive_date).format('D/M/YYYY hh:mm:ss') : '-');

                          }
                      },
                      {
                          title: 'Status',
                          field: 'status',
                          hozAlign: 'left',
                          vertAlign: 'middle',
                          print: false,
                          download: false,
                          formatter(cell) {
                              if (cell.getData().transaction_type == 1) {
                                if (cell.getData().transaction_status == 1 && cell.getData().status == 1) {
                                    return `<div class="flex items-center lg:justify-center text-theme-1">
                                      On Hold
                                    </div>`
                                } else if (cell.getData().transaction_status == 2 && cell.getData().status == 1) {
                                    return `<div class="flex items-center lg:justify-center text-theme-9">
                                    Accepted
                                    </div>`
                                } else if (cell.getData().status == 2) {
                                    if (cell.getData().issue_by == userDetails.value.id) {
                                        return `<div class="flex items-center lg:justify-center text-theme-9">
                                        Received
                                        </div>`
                                    } else if (cell.getData().manager_id == userDetails.value.account_id){
                                        return `<div class="flex items-center lg:justify-center text-theme-9">
                                        Accepted
                                        </div>`

                                    } else {
                                      return `<div class="flex items-center lg:justify-center text-theme-9">
                                        Received
                                        </div>`
                                    }
                                } else if (cell.getData().transaction_status == 3) {
                                    return `<div class="flex items-center lg:justify-center text-theme-6">
                                      Cancelled
                                    </div>`
                                }
                              } else if (cell.getData().transaction_type == 2) {
                                if ([3, 4, 5].includes(cell.getData().process_type)) {
                                    if (cell.getData().transaction_status == 1 && cell.getData().status == 1) {
                                        return `<div class="flex items-center lg:justify-center text-theme-1">
                                          On Hold
                                        </div>`
                                    } else if (cell.getData().transaction_status == 2 && cell.getData().status == 1) {
                                        if (cell.getData().issue_by == userDetails.value.id) {
                                            return `<div class="flex items-center lg:justify-center text-theme-9">
                                            In Process
                                            </div>`
                                        } else {
                                            return `<div class="flex items-center lg:justify-center text-theme-9">
                                            Accepted
                                            </div>`
                                        }
                                    } else if (cell.getData().status == 2) {
                                        if (cell.getData().issue_by == userDetails.value.id) {
                                            return `<div class="flex items-center lg:justify-center text-theme-9">
                                            Received
                                            </div>`
                                        } else {
                                            return `<div class="flex items-center lg:justify-center text-theme-9">
                                            Accepted
                                            </div>`

                                        }
                                    } else if (cell.getData().transaction_status == 3) {
                                        return `<div class="flex items-center lg:justify-center text-theme-6">
                                          Cancelled
                                        </div>`
                                    }
                                } else {
                                  if (cell.getData().status == 1) {
                                      return `<div class="flex items-center lg:justify-center font-bold text-theme-9">
                                        In Process
                                      </div>`
                                  } else if (cell.getData().status == 2) {
                                      return `<div class="flex items-center lg:justify-center text-theme-6">
                                      Received
                                      </div>`
                                  } else if (cell.getData().status == 3) {
                                      return `<div class="flex items-center lg:justify-center text-theme-6">
                                        Cancelled
                                      </div>`
                                  }
                                }
                              } else if ([3, 4].includes(cell.getData().transaction_type)) {
                                  if (cell.getData().transaction_status == 1 || cell.getData().status == 1) {
                                      return `<div class="flex items-center lg:justify-center text-theme-1">
                                        On Hold
                                      </div>`
                                  } else if (cell.getData().status == 2) {
                                      return `<div class="flex items-center lg:justify-center text-theme-9">
                                      Accepted
                                      </div>`
                                  } else if (cell.getData().transaction_status == 3) {
                                      return `<div class="flex items-center lg:justify-center text-theme-6">
                                        Cancelled
                                      </div>`
                                  }
                              }
                          }
                      },
                      {
                          title: 'Action',
                          minWidth: 80,
                          field: '',
                          responsive: 1,
                          hozAlign: 'center',
                          vertAlign: 'middle',
                          headerSort: false,
                          print: false,
                          download: false,
                          formatter(cell) {
                              const data_id = cell.getData().id;

                              if (cell.getData().issue_by == userDetails.value.id && cell.getData().transaction_status == 1) {
                                const a = cash(`<div class="flex lg:justify-center items-center">
                                  <button class="btn btn-outline-danger btn-sm flex items-center mr-3">
                                    Withdraw
                                  </button>
                                </div>`)
                                cash(a).on('click', function () {
                                    cancelJangad(cell.getData().id);
                                })
                                return a[0]
                              } else if (cell.getData().manager_id == userDetails.value.account_id && cell.getData().transaction_status == 1) {
                                const a = cash(`<div class="flex lg:justify-center items-center">
                                  <button class="btn btn-outline-primary btn-sm flex items-center mr-3">
                                    <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Accept
                                  </button>
                                </div>`)
                                cash(a).on('click', function () {
                                    editTransactionStatus(cell.getData().id, cell.getData().issue_by, 'accept');
                                })

                                return a[0]
                              } else {

                              }

                          }
                      },
                    ],
                    renderComplete() {
                        feather.replace({
                            'stroke-width': 1.5
                        })
                    }
                })
            } else {
              tabulator.value = new Tabulator(tableRef.value, {
                ajaxURL: BASE_URL + 'api/purchase/packet_transactions_by_user',
                ajaxConfig: {
                    method: "GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                ajaxParams: {
                    employee_type: screenData.employee_type == '0' ? '' : window.btoa(screenData.employee_type),
                    employee_id: screenData.employee_id == '0' ? '' : window.btoa(screenData.employee_id),
                    process_id: screenData.process_id == '0' ? '' : window.btoa(screenData.process_id),
                    status: screenData.status == '0' ? '' : window.btoa(screenData.status),
                    manager_records: (screenData.manager_records == true ? 1 : "")
                },
                  ajaxFiltering: true,
                  ajaxSorting: true,
                  printAsHtml: true,
                  printStyled: true,
                  pagination: 'remote',
                  paginationSize: 20,
                  paginationSizeSelector: [20, 40, 50],
                  layout: 'fitColumns',
                  responsiveLayout: false,
                  tooltipsHeader: true,
                  headerSort: false,
                  placeholder: 'No matching records found',
                  columns: [

                      // For HTML table
                      {
                          title: 'Transaction No',
                          minWidth: 200,
                          responsive: 0,
                          field: 'transaction_code',
                          vertAlign: 'middle',
                          print: false,
                          download: true,

                          formatter(cell) {

                            if (cell.getData().issue_by == userDetails.value.id) {
                              const a = cash(`<div><i data-feather="arrow-up-right" class="w-6 h-6 font-bold text-theme-9 mr-1"></i>
                 ${
                  cell.getData().transaction_code
                 }
                </div>`)
                                cash(a).on('click', function () {
                                    router.push({
                                        'path': '/packets/transaction/' + window.btoa(cell.getData().id)
                                    })
                                })
                                return a[0]
                            } else if (cell.getData().manager_id == userDetails.value.account_id) {
                              const a = cash(`<div><i data-feather="arrow-down-left" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>
                 ${
                  cell.getData().transaction_code
                 }
                </div>`)
                                cash(a).on('click', function () {
                                    router.push({
                                        'path': '/packets/transaction/' + window.btoa(cell.getData().id)
                                    })
                                })
                                return a[0]
                            } else {
                              const a = cash(`<div><i data-feather="arrow-up-right" class="w-6 h-6 font-bold text-theme-9 mr-1"></i>
                 ${
                  cell.getData().transaction_code
                 }
                </div>`)
                                cash(a).on('click', function () {
                                    router.push({
                                        'path': '/packets/transaction/' + window.btoa(cell.getData().id)
                                    })
                                })
                                return a[0]
                            }

                          }
                      },
                      {
                        title: 'Pieces',
                        field: 'pieces',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Issue Weight',
                        field: 'issue_weight',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                        formatter(cell) {
                            return `<div class="text-md">
                <p class="font-12 font-bold text-md">` + Number(cell.getData().issue_weight).toFixed(4) + ` ct </p>
              </div>`;
                        }
                    },
                    {

                        title: 'Issue By',
                        field: 'issue_by',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                        formatter(cell) {
                          return `<div class="">` + cell.getData().issue_manager_first_name + ' ' + cell.getData().issue_manager_last_name + `</div>`;
                        }
                    },
                    {

                        title: 'Manager/Worker',
                        field: 'karigar_first_name',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                        formatter(cell) {
                            if (cell.getData().issue_by == userDetails.value.id) {

                                if (cell.getData().transaction_type == 1) {
                                    return `<div class="">` + cell.getData().issue_manager_first_name + ' ' + cell.getData().issue_manager_last_name + `<br>
                                      <p class="text-theme-9 font-12 font-bold">` + 'Transfer' + `</p>
                                    </div>`;
                                } else if (cell.getData().transaction_type == 2) {
                                    if (cell.getData().process_type == 1 || cell.getData().process_type == 2 || cell.getData().process_type == 5 || cell.getData().process_type == 6 || cell.getData().process_type == 7 || cell.getData().process_type == 8 || cell.getData().process_type == 9) {

                                        if (cell.getData().employee_role == 7) {
                                            return `<div class="">` + cell.getData().karigar_first_name + ' ' + cell.getData().karigar_last_name + `<br>
                                                <p class="text-theme-9 font-12 font-bold">` + cell.getData().process_name + `</p>
                                            </div>`;
                                        } else if (cell.getData().employee_role == 4) {
                                            return `<div class="">` + cell.getData().pp_first_name + ' ' + cell.getData().pp_last_name + `<br>
                                                <p class="text-theme-9 font-12 font-bold">` + cell.getData().process_name + `</p>
                                            </div>`;
                                        }
                                    } else if (cell.getData().process_type == 3 || cell.getData().process_type == 4) {
                                        return `<div class="">` + cell.getData().planner_first_name + ' ' + cell.getData().planner_last_name + `<br>
                                            <p class="text-theme-9 font-12 font-bold">` + cell.getData().process_name + `</p>
                                        </div>`;
                                    }
                                } else if (cell.getData().transaction_type == 4) {
                                    return `<div class="">` + cell.getData().issue_manager_first_name + ' ' + cell.getData().issue_manager_last_name + `<br>
                                      <p class="text-theme-9 font-12 font-bold">` + 'Job Done' + `</p>
                                    </div>`;
                                } else if (cell.getData().transaction_type == 3) {
                                    return `<div class="">` + cell.getData().issue_manager_first_name + ' ' + cell.getData().issue_manager_last_name + `<br>
                                        <p class="text-theme-9 font-12 font-bold">` + 'Process Done' + `</p>
                                      </div>`;
                                }

                            } else if (cell.getData().manager_id == userDetails.value.account_id) {
                              if (cell.getData().transaction_type == 1) {
                                    return `<div class="">` + cell.getData().manager_first_name + ' ' + cell.getData().manager_last_name + `<br>
                                      <p class="text-theme-9 font-12 font-bold">` + 'Transfer' + `</p>
                                    </div>`;
                                } else if (cell.getData().transaction_type == 2) {
                                    return `<div class="">` + cell.getData().manager_first_name + ' ' + cell.getData().manager_last_name + `<br>
                                      <p class="text-theme-9 font-12 font-bold">` + 'Transfer' + `</p>
                                    </div>`;
                                } else if (cell.getData().transaction_type == 4) {
                                    return `<div class="">` + cell.getData().manager_first_name + ' ' + cell.getData().manager_first_name + `<br>
                                      <p class="text-theme-9 font-12 font-bold">` + 'Job Done' + `</p>
                                    </div>`;
                                } else if (cell.getData().transaction_type == 3) {
                                    return `<div class="">` + cell.getData().manager_first_name + ' ' + cell.getData().manager_first_name + `<br>
                                    <p class="text-theme-9 font-12 font-bold">` + 'Process Done' + `</p>
                                  </div>`;
                                }
                            } else {
                              if (cell.getData().transaction_type == 1) {
                                    return `<div class="">` + cell.getData().issue_manager_first_name + ' ' + cell.getData().issue_manager_last_name + `<br>
                                      <p class="text-theme-9 font-12 font-bold">` + 'Transfer' + `</p>
                                    </div>`;
                                } else if (cell.getData().transaction_type == 2) {
                                    if (cell.getData().process_type == 1 || cell.getData().process_type == 2 || cell.getData().process_type == 5 || cell.getData().process_type == 6 || cell.getData().process_type == 7 || cell.getData().process_type == 8 || cell.getData().process_type == 9) {

                                        if (cell.getData().employee_role == 7) {
                                            return `<div class="">` + cell.getData().karigar_first_name + ' ' + cell.getData().karigar_last_name + `<br>
                                                <p class="text-theme-9 font-12 font-bold">` + cell.getData().process_name + `</p>
                                            </div>`;
                                        } else if (cell.getData().employee_role == 4) {
                                            return `<div class="">` + cell.getData().pp_first_name + ' ' + cell.getData().pp_last_name + `<br>
                                                <p class="text-theme-9 font-12 font-bold">` + cell.getData().process_name + `</p>
                                            </div>`;
                                        }
                                    } else if (cell.getData().process_type == 3 || cell.getData().process_type == 4) {
                                        return `<div class="">` + cell.getData().planner_first_name + ' ' + cell.getData().planner_last_name + `<br>
                                            <p class="text-theme-9 font-12 font-bold">` + cell.getData().process_name + `</p>
                                        </div>`;
                                    }
                                } else if (cell.getData().transaction_type == 4) {
                                    return `<div class="">` + cell.getData().issue_manager_first_name + ' ' + cell.getData().issue_manager_last_name + `<br>
                                      <p class="text-theme-9 font-12 font-bold">` + 'Job Done' + `</p>
                                    </div>`;
                                } else if (cell.getData().transaction_type == 3) {
                                    return `<div class="">` + cell.getData().issue_manager_first_name + ' ' + cell.getData().issue_manager_last_name + `<br>
                                      <p class="text-theme-9 font-12 font-bold">` + 'Process Done' + `</p>
                                    </div>`;
                                }
                            }
                        }
                    },
                    {
                        title: 'Issue/ Receive Date',
                        field: 'created_at',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                        formatter(cell) {
                            return moment(cell.getData().created_at).format('D/M/YYYY hh:mm:ss') +  '<br>' + (cell.getData().receive_date ? moment(cell.getData().receive_date).format('D/M/YYYY hh:mm:ss') : '-');

                        }
                    },
                    {
                        title: 'Status',
                        field: 'status',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {
                            if (cell.getData().transaction_type == 1) {
                              if (cell.getData().transaction_status == 1 && cell.getData().status == 1) {
                                  return `<div class="flex items-center lg:justify-center text-theme-1">
                                    On Hold
                                  </div>`
                              } else if (cell.getData().transaction_status == 2 && cell.getData().status == 1) {
                                  return `<div class="flex items-center lg:justify-center text-theme-9">
                                  Accepted
                                  </div>`
                              } else if (cell.getData().status == 2) {
                                  if (cell.getData().issue_by == userDetails.value.id) {
                                      return `<div class="flex items-center lg:justify-center text-theme-9">
                                      Received
                                      </div>`
                                  } else if (cell.getData().manager_id == userDetails.value.account_id){
                                      return `<div class="flex items-center lg:justify-center text-theme-9">
                                      Accepted
                                      </div>`

                                  } else {
                                    return `<div class="flex items-center lg:justify-center text-theme-9">
                                      Received
                                      </div>`
                                  }
                              } else if (cell.getData().transaction_status == 3) {
                                  return `<div class="flex items-center lg:justify-center text-theme-6">
                                    Cancelled
                                  </div>`
                              }
                            } else if (cell.getData().transaction_type == 2) {
                              if ([3, 4, 5].includes(cell.getData().process_type)) {
                                  if (cell.getData().transaction_status == 1 && cell.getData().status == 1) {
                                      return `<div class="flex items-center lg:justify-center text-theme-1">
                                        On Hold
                                      </div>`
                                  } else if (cell.getData().transaction_status == 2 && cell.getData().status == 1) {
                                      if (cell.getData().issue_by == userDetails.value.id) {
                                          return `<div class="flex items-center lg:justify-center text-theme-9">
                                          In Process
                                          </div>`
                                      } else {
                                          return `<div class="flex items-center lg:justify-center text-theme-9">
                                          Accepted
                                          </div>`
                                      }
                                  } else if (cell.getData().status == 2) {
                                      if (cell.getData().issue_by == userDetails.value.id) {
                                          return `<div class="flex items-center lg:justify-center text-theme-9">
                                          Received
                                          </div>`
                                      } else {
                                          return `<div class="flex items-center lg:justify-center text-theme-9">
                                          Accepted
                                          </div>`

                                      }
                                  } else if (cell.getData().transaction_status == 3) {
                                      return `<div class="flex items-center lg:justify-center text-theme-6">
                                        Cancelled
                                      </div>`
                                  }
                              } else {
                                if (cell.getData().status == 1) {
                                    return `<div class="flex items-center lg:justify-center font-bold text-theme-9">
                                      In Process
                                    </div>`
                                } else if (cell.getData().status == 2) {
                                    return `<div class="flex items-center lg:justify-center text-theme-6">
                                    Received
                                    </div>`
                                } else if (cell.getData().status == 3) {
                                    return `<div class="flex items-center lg:justify-center text-theme-6">
                                      Cancelled
                                    </div>`
                                }
                              }
                            } else if ([3, 4].includes(cell.getData().transaction_type)) {
                                if (cell.getData().transaction_status == 1 || cell.getData().status == 1) {
                                    return `<div class="flex items-center lg:justify-center text-theme-1">
                                      On Hold
                                    </div>`
                                } else if (cell.getData().status == 2) {
                                    return `<div class="flex items-center lg:justify-center text-theme-9">
                                    Accepted
                                    </div>`
                                } else if (cell.getData().transaction_status == 3) {
                                    return `<div class="flex items-center lg:justify-center text-theme-6">
                                      Cancelled
                                    </div>`
                                }
                            }
                        }
                    },
                    {
                        title: 'Action',
                        minWidth: 80,
                        field: '',
                        responsive: 1,
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        headerSort: false,
                        print: false,
                        download: false,
                        formatter(cell) {
                            const data_id = cell.getData().id;

                            if (cell.getData().issue_by == userDetails.value.id && cell.getData().transaction_status == 1) {
                              const a = cash(`<div class="flex lg:justify-center items-center">
                                <button class="btn btn-outline-danger btn-sm flex items-center mr-3">
                                  Withdraw
                                </button>
                              </div>`)
                              cash(a).on('click', function () {
                                  cancelJangad(cell.getData().id);
                              })
                              return a[0]
                            } else if (cell.getData().manager_id == userDetails.value.account_id && cell.getData().transaction_status == 1) {
                              const a = cash(`<div class="flex lg:justify-center items-center">
                                <button class="btn btn-outline-primary btn-sm flex items-center mr-3">
                                  <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Accept
                                </button>
                              </div>`)
                              cash(a).on('click', function () {
                                  editTransactionStatus(cell.getData().id, cell.getData().issue_by, 'accept');
                              })

                              return a[0]
                            } else {

                            }

                        }
                    },
                  ],
                  renderComplete() {
                      feather.replace({
                          'stroke-width': 1.5
                      })
                  }
              })
            }
        }

        const getEmployeeDetails = (item) => {

            if (screenData.process_id == 'manager_transfer') {
                screenData.employee_id = '0'
                screenData.employees = []
                initTabulator()
            } else {

                let params = {
                    employee_type: window.btoa(screenData.employee_type),
                    process_id: screenData.process_id == '0' ? '' : window.btoa(screenData.process_id),
                }
                let api_url = ''

                if (filter.department_id == '0') {

                    api_url = 'api/master/get_employees_by_manager_employee_type'
                } else {
                    api_url = 'api/master/get_employees_by_manager_employee_type'
                }
                let promise = axios({
                    url: api_url,
                    method: "post",
                    data: params,
                    baseURL: BASE_URL,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    },
                    config: {
                        headers: {
                            Accept: "application/json"
                        }
                    }
                });
                return promise
                    .then(result => result.data)
                    .then(data => {
                        screenData.employees = data.items
                        if (screenData.employee_id != '0') {

                          if(screenData.emp_hit == 2) {


                            screenData.employee_id = '0'
                          } else {
                            screenData.emp_hit++;
                          }
                        } else {

                            initTabulator()
                            // screenData.employee_id = '0'
                        }
                    })
                    .catch(_error => {
                        screenData.employees = []
                        // initTabulator()
                        if (screenData.employee_id != '0') {

                          if(screenData.emp_hit == 2) {

                            screenData.employee_id = '0'
                          } else {
                            screenData.emp_hit++;
                          }
                        } else {

                            initTabulator()
                            // screenData.employee_id = '0'
                        }
                    });
            }

        }

        const getProcessDetails = async () => {
            let params = {
                // id: window.btoa(screenData.manager_id)
            }
            let api_url = ''

            if (filter.manager_id == '0') {

                api_url = 'api/master/dp_process'
            } else {
                api_url = 'api/master/dp_process'
            }
            let promise = axios({
                url: api_url,
                method: "post",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    // screenData.process_id = '0'
                    screenData.process_items = data.items
                })
                .catch(_error => {
                    // screenData.process_id = '0'
                    screenData.process_items = []
                });
        }

        // Redraw table onresize
        const reInitOnResizeWindow = () => {
            window.addEventListener('resize', () => {
                tabulator.value.redraw()
                feather.replace({
                    'stroke-width': 1.5
                })
            })
        }

        // Filter function
        const onFilter = () => {
            tabulator.value.setFilter(filter.field, filter.type, filter.value)
        }

        //Edit user
        const editUser = (id) => {
            console.log(id);
        }

        //Edit user
        const editTransactionStatus = (id, user_id, action) => {
            console.log(id);
            console.log(user_id);
            let trans_id = id;
            let sender_type = '';
            if (user_id == userDetails.value.id) {
                sender_type = "sender";
            } else {
                sender_type = "receiver";
            }

            const params = {
                "trans_id": trans_id,
                'sender_type': sender_type,
                'action': action
            };

            swal({
                title: "Are you sure?",
                text: "You are about to Change transaction status!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm & Accept",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                preConfirm: login => {

                    let promise = axios({
                        url: "api/purchase/hold_action_packet_trans",
                        method: "post",
                        data: params,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {
                            console.log(data.message);
                            if (data.status == "error") {
                                swal("Error!", data.message, "error");
                            } else {
                                router.go();
                            }
                        })
                        .catch(_error => {
                            return [];
                        });
                },
                allowOutsideClick: () => !swal.isLoading()
            }).then(result => {
                if (result.value) {
                    //swal("Success!", "User Password Updated.", "success");
                }
            });
        }

        //Edit user
        const cancelJangad = (id) => {
            let trans_id = id;

            const params = {
                "id": window.btoa(trans_id),
            };

            swal({
                title: "Are you sure?",
                text: "You are about to Withdraw!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm & Accept",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                preConfirm: login => {

                    let promise = axios({
                        url: "api/process/cancle_jangad",
                        method: "post",
                        data: params,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {
                            console.log(data.message);
                            if (data.status == "error") {
                                swal("Error!", data.message, "error");
                              } else {
                                swal("Success!", data.message, "success");
                                initTabulator()
                            }
                        })
                        .catch(_error => {
                            return [];
                        });
                },
                allowOutsideClick: () => !swal.isLoading()
            }).then(result => {
                if (result.value) {
                    //swal("Success!", "User Password Updated.", "success");
                }
            });
        }

        // On reset filter
        const onResetFilter = () => {
            filter.field = 'first_name'
            filter.type = 'like'
            filter.value = ''
            onFilter()
        }

        // Export
        const onExportCsv = () => {
            tabulator.value.download('csv', 'data.csv')
        }

        const onExportJson = () => {
            tabulator.value.download('json', 'data.json')
        }

        const onExportXlsx = () => {
            const win = window
            win.XLSX = xlsx
            tabulator.value.download('xlsx', 'buyer_list.xlsx', {
                sheetName: 'Buyers'
            })
        }

        const onExportHtml = () => {
            tabulator.value.download('html', 'data.html', {
                style: true
            })
        }

        // Print
        const onPrint = () => {
            tabulator.value.print()
        }

        onMounted(() => {

          console.log(userDetails.value)

          const filters =  JSON.parse(localStorage.getItem('transaction_filters'))

          console.log(filters)

          if(filters) {

            screenData.employee_type = (filters.employee_type ? filters.employee_type : '3')
            screenData.employee_id = (filters.employee_id ? filters.employee_id : '0')
            screenData.process_id = (filters.process_id ? filters.process_id : '0')
            screenData.status = (filters.status ? filters.status : '0')
          }
          // getEmployeeDetails('create')
          getProcessDetails()
          initTabulator()
            reInitOnResizeWindow()
        })

        return {
            tableRef,
            screenData,
            userDetails,
            filter,
            onFilter,
            onResetFilter,
            onExportCsv,
            onExportJson,
            onExportXlsx,
            onExportHtml,
            initTabulator,
            getEmployeeDetails,
            onPrint,
            editUser
        }

    }
})
</script>
